/* cspell: disable */
import { createTheme, PaletteMode, ThemeOptions } from '@mui/material';
import { TypographyStyleOptions } from '@mui/material/styles/createTypography';

const rsColors = {
  error: {
    main: '#FF7A62',
    dark: '#e63746',
  },
  info: {
    main: '#3aa5fc',
  },
  primary: {
    light: '#7995df',
    main: '#232A35',
  },
  secondary: {
    light: '#c1c7d0',
    main: '#A5ADBA',
    dark: '#232a35',
  },
  slate: {
    p50: '#FAFBFC',
    p100: '#F3F4F6',
    p200: '#DFE1E6',
    p300: '#C1C7D0',
    p400: '#A5ADBA',
    p500: '#8992A5',
    p600: '#5E6B85',
    p700: '#424C60',
    p800: '#232A35',
    p900: '#151920',
  },
  success: {
    main: '#93DFC2',
  },
  text: {
    primary: '#FFFFFF',
    secondary: '#5E6B85',
    disabled: '#A5ADBA',
  },
  warning: {
    main: '#FBE35A',
  },
  white: '#ffffff',
};

declare module '@mui/material/styles' {
  interface ExtendedColors {
    dandelion: string;
    lightOrange: string;
    lightPink: string;
    orange: string;
    pink: string;
    roofstockOrange: string;
    seaFoam: string;
  }

  interface Palette {
    slate: typeof rsColors.slate;
    extended: ExtendedColors;
  }

  interface PaletteOptions {
    slate: typeof rsColors.slate;
    extended: ExtendedColors;
  }

  interface TypographyVariants {
    body1Semibold: TypographyStyleOptions;
    body1Bold: TypographyStyleOptions;
    body2Bold: TypographyStyleOptions;
    captionBold: TypographyStyleOptions;
    labelLarge: TypographyStyleOptions;
    labelSmall: TypographyStyleOptions;
    subtitle1Semibold: TypographyStyleOptions;
    subtitle1Bold: TypographyStyleOptions;
    subtitle2Semibold: TypographyStyleOptions;
    subtitle2Bold: TypographyStyleOptions;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    body1Semibold: TypographyStyleOptions;
    body1Bold: TypographyStyleOptions;
    body2Semibold: TypographyStyleOptions;
    body2Bold: TypographyStyleOptions;
    captionBold: TypographyStyleOptions;
    labelLarge: TypographyStyleOptions;
    labelSmall: TypographyStyleOptions;
    subtitle1Semibold: TypographyStyleOptions;
    subtitle1Bold: TypographyStyleOptions;
    subtitle2Semibold: TypographyStyleOptions;
    subtitle2Bold: TypographyStyleOptions;
  }
}

declare module '@mui/material/Checkbox' {
  interface CheckboxClasses {
    colorWarning: string;
  }
}

// https://mui.com/material-ui/customization/typography/#adding-amp-disabling-variants
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body1Semibold: true;
    body1Bold: true;
    body2Semibold: true;
    body2Bold: true;
    captionBold: true;
    labelLarge: true;
    labelSmall: true;
    subtitle1Semibold: true;
    subtitle1Bold: true;
    subtitle2Semibold: true;
    subtitle2Bold: true;
  }
}

const fontFamily = '"Roboto Mono", monospace';
const secondaryFontFamily = '"Roboto", Muli, sans-serif';

const getThemeOptions = (mode: PaletteMode = 'dark'): ThemeOptions => {
  return {
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 992,
        lg: 1200,
        xl: 1440,
      },
    },
    spacing: 8,
    mixins: {
      toolbar: {
        minHeight: 100,
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            padding: 0,
            margin: 0,
            fontFamily,
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            color: rsColors.white,
            fontFamily: secondaryFontFamily,
            fontSize: '1.125rem',
            fontWeight: 700,
            letterSpacing: 'normal',
            lineHeight: '1',
            textTransform: 'none',
          },
          outlined: {
            borderStyle: 'solid',
            borderWidth: '2px',
            '&:hover': {
              borderWidth: '2px',
            },
            '&:disabled': {
              borderWidth: '2px',
            },
          },
          outlinedPrimary: {
            borderColor: rsColors.white,
            color: rsColors.white,
            '&:hover': {
              backgroundColor: 'transparent',
              borderColor: rsColors.white,
            },
            '&:disabled': {
              backgroundColor: 'transparent',
              borderColor: rsColors.white,
            },
          },
          outlinedSecondary: {
            backgroundColor: rsColors.white,
            borderColor: rsColors.slate.p900,
            color: rsColors.slate.p900,
            '&:hover': {
              backgroundColor: rsColors.slate.p100,
              borderColor: rsColors.slate.p900,
            },
            '&:disabled': {
              backgroundColor: rsColors.white,
              borderColor: rsColors.white,
            },
          },
          outlinedSuccess: {
            color: rsColors.success.main,
          },
          outlinedSizeSmall: {
            padding: '0.5rem 1.5rem',
            fontSize: '0.875rem',
            fontWeight: 800,
          },
          outlinedSizeMedium: {
            padding: '1rem 2rem',
            fontSize: '1.125rem',
            fontWeight: 800,
          },
          outlinedSizeLarge: {
            padding: '1.5rem 2.5rem',
            fontSize: '1.25rem',
            fontWeight: 800,
          },
          contained: {
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none',
            },
            '&:disabled': {
              boxShadow: 'none',
            },
          },
          containedPrimary: {
            backgroundColor: rsColors.white,
            color: rsColors.slate.p900,
            '&:hover': {
              backgroundColor: rsColors.slate.p100,
            },
            '&:disabled': {
              backgroundColor: rsColors.white,
              color: rsColors.slate.p900,
              opacity: 0.5,
            },
          },
          containedSecondary: {
            backgroundColor: rsColors.slate.p800,
            color: rsColors.white,
          },
          containedSizeSmall: {
            padding: '0.5rem 1.5rem',
            fontSize: '0.875rem',
            fontWeight: 800,
          },
          containedSizeMedium: {
            padding: '1rem 2rem',
            fontSize: '1.125rem',
            fontWeight: 800,
          },
          containedSizeLarge: {
            padding: '1.5rem 2.5rem',
            fontSize: '1.25rem',
            fontWeight: 800,
          },
          text: {
            padding: 0,
          },
          textWarning: {
            color: '#FBE35A',
          },
          textSizeSmall: {
            fontSize: '0.875rem',
          },
        },
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            backgroundColor: 'rgba(35, 42, 53, 0.8)',
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            '&.Mui-checked': {
              color: '#ffffff',
            },
          },
          colorWarning: {
            '&.Mui-checked': {
              color: '#FBE35A',
            },
          },
        },
      },
      MuiContainer: {
        defaultProps: {
          maxWidth: 'xl',
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            borderColor: rsColors.slate.p700,
          },
          light: {
            borderColor: rsColors.white,
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            backgroundColor: rsColors.slate.p800,
            backgroundImage: 'none',
          },
          paperAnchorLeft: {
            width: '350px',
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            backgroundColor: rsColors.white,
            borderRadius: '4px',
            color: rsColors.text.secondary,
            '&:hover': {
              backgroundColor: rsColors.white,
            },
            '&;focus': {
              backgroundColor: rsColors.white,
            },
          },
          input: {
            backgroundColor: rsColors.white,
            borderRadius: '4px',
            color: rsColors.text.secondary,
            padding: '12px',
            '&:hover': {
              backgroundColor: rsColors.white,
            },
            '&;focus': {
              backgroundColor: rsColors.white,
            },
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          root: {
            '.MuiBackdrop-root': {
              backgroundColor: 'transparent',
            },
          },
          paper: {
            backgroundColor: rsColors.slate.p800,
          },
          list: {
            backgroundColor: rsColors.slate.p900,
            borderWidth: '2px',
            borderStyle: 'solid',
            borderColor: 'white',
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontSize: '1.125rem',
            fontWeight: 800,
            fontFamily: secondaryFontFamily,
            lineHeight: '0.875rem',
            letterSpacing: '1px',
          },
        },
      },
      MuiModal: {
        styleOverrides: {
          root: {
            outline: 'none',
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            background: 'transparent',
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            verticalAlign: 'middle',
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: 'none',
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            position: 'relative',
            borderRadius: '4px',
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            backgroundColor: rsColors.slate.p800,
          },
        },
      },
    },
    palette: {
      mode,
      // TODO: slowly move the colors that depend on `mode` into this conditional spread.
      ...(mode === 'dark'
        ? {
            primary: {
              light: rsColors.primary.light,
              main: rsColors.white,
              contrastText: rsColors.white,
            },
          }
        : {
            primary: {
              light: rsColors.primary.light,
              dark: rsColors.white,
              main: rsColors.primary.main,
              contrastText: rsColors.white,
            },
          }),
      divider: rsColors.slate.p200,
      error: {
        main: rsColors.error.main,
        dark: rsColors.error.dark,
        contrastText: rsColors.white,
      },
      info: {
        main: rsColors.info.main,
        contrastText: rsColors.white,
      },
      secondary: {
        light: rsColors.secondary.light,
        main: rsColors.secondary.main,
        dark: rsColors.secondary.dark,
        contrastText: rsColors.white,
      },
      slate: {
        p50: rsColors.slate.p50,
        p100: rsColors.slate.p100,
        p200: rsColors.slate.p200,
        p300: rsColors.slate.p300,
        p400: rsColors.slate.p400,
        p500: rsColors.slate.p500,
        p600: rsColors.slate.p600,
        p700: rsColors.slate.p700,
        p800: rsColors.slate.p800,
        p900: rsColors.slate.p900,
      },
      success: {
        main: rsColors.success.main,
        contrastText: rsColors.white,
      },
      text: {
        primary: rsColors.text.primary,
        secondary: rsColors.text.secondary,
        disabled: rsColors.text.disabled,
      },
      warning: {
        main: rsColors.warning.main,
        contrastText: rsColors.white,
      },
      extended: {
        dandelion: '#FBE35A',
        lightOrange: '#FFC595',
        lightPink: '#E7C0E5',
        orange: '#FD9D4F',
        pink: '#FCBABE',
        roofstockOrange: '#FA8E36',
        seaFoam: '#93DFC2',
      },
    },
    shape: {
      borderRadius: 4,
    },
    typography: {
      fontFamily: fontFamily,
      fontSize: 16,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 600,
      fontWeightBold: 700,
      htmlFontSize: 16,
      h1: {
        fontSize: '4.5rem', // equivalent to 72px
        fontWeight: 700,
        fontFamily: fontFamily,
        letterSpacing: '-.053em',
        lineHeight: '100%',
      },
      h2: {
        fontSize: '3.5rem', // equivalent to 56px
        fontWeight: 700,
        fontFamily: fontFamily,
        letterSpacing: '0em', // equivalent to 0px
        lineHeight: '110%',
      },
      h3: {
        fontSize: '3rem', // equivalent to 48px
        fontWeight: 700,
        fontFamily: fontFamily,
        letterSpacing: 'normal',
        lineHeight: '110%',
      },
      h4: {
        fontSize: '2.5rem', // equivalent to 40px
        fontWeight: 700,
        fontFamily: fontFamily,
        letterSpacing: 'normal',
        lineHeight: '110%',
      },
      h5: {
        fontSize: '2rem', // equivalent to 32px
        fontWeight: 700,
        fontFamily: fontFamily,
        letterSpacing: '0em', // equivalent to 0px
        lineHeight: '110%',
      },
      h6: {
        fontSize: '1.5rem', // equivalent to 24px
        fontWeight: 700,
        fontFamily: fontFamily,
        letterSpacing: '0em', // equivalent to 0px
        lineHeight: '120%',
      },
      subtitle1: {
        fontSize: '1.875rem', // equivalent to 30px
        fontWeight: 400,
        fontFamily: secondaryFontFamily,
        letterSpacing: 'normal',
        lineHeight: 1.2,
      },
      subtitle1Semibold: {
        fontSize: '1.875rem', // equivalent to 30px
        fontWeight: 600,
        fontFamily: secondaryFontFamily,
        letterSpacing: 'normal',
        lineHeight: 1.2,
      },
      subtitle1Bold: {
        fontSize: '1.875rem', // equivalent to 30px
        fontWeight: 900,
        fontFamily: secondaryFontFamily,
        letterSpacing: 'normal',
        lineHeight: 1.2,
      },
      subtitle2: {
        fontSize: '1.25rem',
        fontWeight: 400,
        fontFamily: secondaryFontFamily,
        letterSpacing: '0.031em', // equivalent to 0.5px
        lineHeight: 1.2,
      },
      subtitle2Semibold: {
        fontSize: '1.25rem',
        fontWeight: 600,
        fontFamily: secondaryFontFamily,
        letterSpacing: '0.031em', // equivalent to 0.5px
        lineHeight: 1.2,
      },
      subtitle2Bold: {
        fontSize: '1.25rem',
        fontWeight: 900,
        fontFamily: secondaryFontFamily,
        letterSpacing: '0.031em', // equivalent to 0.5px
        lineHeight: 1.2,
      },
      body1: {
        fontSize: '1rem', // equivalent to 16px
        fontWeight: 400,
        fontFamily: secondaryFontFamily,
        letterSpacing: 'normal',
        lineHeight: '140%',
      },
      body1Semibold: {
        fontSize: '1rem', // equivalent to 16px
        fontWeight: 600,
        fontFamily: secondaryFontFamily,
        letterSpacing: 'normal',
        lineHeight: '140%',
      },
      body1Bold: {
        fontSize: '1rem', // equivalent to 16px
        fontWeight: 700,
        fontFamily: secondaryFontFamily,
        letterSpacing: 'normal',
        lineHeight: '140%',
      },
      body2: {
        fontSize: '0.875rem', // equivalent to 14px
        fontWeight: 400,
        fontFamily: secondaryFontFamily,
        letterSpacing: 'normal',
        lineHeight: '140%',
      },
      body2Semibold: {
        fontSize: '0.875rem', // equivalent to 14px
        fontWeight: 600,
        fontFamily: secondaryFontFamily,
        letterSpacing: 'normal',
        lineHeight: '1.125rem',
      },
      body2Bold: {
        fontSize: '0.875rem', // equivalent to 14px
        fontWeight: 700,
        fontFamily: secondaryFontFamily,
        letterSpacing: 'normal',
        lineHeight: '1.125rem',
      },
      caption: {
        fontSize: '0.75rem', // equivalent to 12px
        fontWeight: 400,
        fontFamily: secondaryFontFamily,
        letterSpacing: 'normal',
        lineHeight: '1rem', // equivalent to 16px
      },
      captionBold: {
        fontSize: '0.75rem', // equivalent to 12px
        fontWeight: 700,
        fontFamily: secondaryFontFamily,
        letterSpacing: 'normal',
        lineHeight: '1rem', // equivalent to 16px
      },
      button: {
        fontSize: '1.125rem',
        fontWeight: 700,
        fontFamily: secondaryFontFamily,
        letterSpacing: '1px',
        lineHeight: '1.25rem',
        textTransform: 'none',
      },
      labelLarge: {
        fontSize: '0.875rem',
        fontWeight: 700,
        fontFamily,
        letterSpacing: '0.125rem',
        lineHeight: '1.5',
        textTransform: 'uppercase',
      },
      labelSmall: {
        fontSize: '0.75rem',
        fontWeight: 700,
        fontFamily,
        letterSpacing: '1px',
        lineHeight: '1.5',
        textTransform: 'uppercase',
      },
    },
    zIndex: {
      mobileStepper: 1000,
      appBar: 1100,
      drawer: 1200,
      modal: 1300,
      snackbar: 1400,
      tooltip: 1600,
    },
  };
};

export const onChainTheme = createTheme(getThemeOptions());
