import { publicConfig } from '../config';

export enum AppName {
  RoofstockOnChain = 'RoofstockOnChain',
}

const segmentWriteKey = publicConfig.analytics.segment.writeKey;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function LogSegmentTrackEvent(event: string, props: { app: AppName } | any): void {
  if (segmentWriteKey) {
    props['source'] = 'next-app';

    if (publicConfig.environmentName !== 'production') {
      console.log('Segment EventTracking: ', event, props);
    }

    if (segmentWriteKey && window && window['analytics']) {
      window['analytics'].track(event, props);
    }
  }
}

export function LogSegmentPageView(): void {
  if (segmentWriteKey && window && window['analytics']) {
    window['analytics'].page();
  }
}
