// See: https://github.com/FortAwesome/react-fontawesome#build-a-library-to-reference-icons-throughout-your-app-more-conveniently

import {
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAsterisk,
  faBars,
  faBath,
  faBed,
  faBell,
  faCalendarAlt,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCopy,
  faCreditCard,
  faDollarSign,
  faDownload,
  faExclamationCircle,
  faExclamationTriangle,
  faExternalLink,
  faInfoCircle,
  faMapMarkerAlt,
  faPhoneAlt,
  faPiggyBank,
  faPlayCircle,
  faQuestionCircle,
  faSearch,
  faSignOut,
  faStar,
  faStickyNote,
  faSync,
  faTimes,
  faTrashAlt,
} from '@fortawesome/pro-regular-svg-icons';

import { library } from '@fortawesome/fontawesome-svg-core';

function addProRegularSvgIconsToLibrary() {
  library.add(
    faBath,
    faBed,
    faCalendarAlt,
    faDownload,
    faCheck,
    faExclamationCircle,
    faExclamationTriangle,
    faExternalLink,
    faPlayCircle,
    faSearch,
    faStar,
    faTimes,
    faCopy,
    faBars,
    faSignOut,
    faCheckCircle,
    faBell,
    faAngleLeft,
    faAsterisk,
    faAngleDown,
    faAngleRight,
    faPhoneAlt,
    faTrashAlt,
    faStickyNote,
    faMapMarkerAlt,
    faBell,
    faAngleRight,
    faAngleLeft,
    faPiggyBank,
    faDollarSign,
    faSync,
    faQuestionCircle,
    faCreditCard,
    faChevronRight,
    faChevronLeft,
    faChevronUp,
    faChevronDown,
    faInfoCircle
  );
}

addProRegularSvgIconsToLibrary();
