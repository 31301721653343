/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Faqs
// ====================================================

export interface Faqs_contents {
  __typename: "Content";
  id: string;
  title: string;
  contentBody: string | null;
  categories: (string | null)[] | null;
  status: ContentStatus;
}

export interface Faqs {
  contents: Faqs_contents[] | null;
}

export interface FaqsVariables {
  contentsInput: ContentsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetListingDocuments
// ====================================================

export interface GetListingDocuments_onChainPublicResourceBlobs {
  __typename: "ResourceBlob";
  fileName: string | null;
  url: string | null;
  lastModifiedUtc: Date | null;
  contentType: string | null;
}

export interface GetListingDocuments {
  onChainPublicResourceBlobs: GetListingDocuments_onChainPublicResourceBlobs[] | null;
}

export interface GetListingDocumentsVariables {
  input: ResourceBlobsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOnChainListingFull
// ====================================================

export interface GetOnChainListingFull_blockchainQuery_onChainListing {
  __typename: "OnChainListing";
  smartContractCryptoAddress: string | null;
  tokenId: string | null;
  cfsListingId: number | null;
  description: string | null;
  mainImageUrl: string | null;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  cbsaCode: number | null;
  country: string;
  market: string | null;
  yearBuilt: number | null;
  bedrooms: Decimal | null;
  bathrooms: Decimal | null;
  squareFeet: number | null;
  lotSize: number | null;
  imageUrls: (string | null)[] | null;
  listPrice: Decimal | null;
  status: OnChainListingStatus;
  id: number;
  propertyType: string;
  videoWalkthroughUrl: string | null;
  isRenovated: boolean | null;
  renovationValue: Decimal | null;
  threeDTourUrl: string | null;
  latitude: Decimal | null;
  longitude: Decimal | null;
}

export interface GetOnChainListingFull_blockchainQuery {
  __typename: "BlockchainQuery";
  onChainListing: GetOnChainListingFull_blockchainQuery_onChainListing | null;
}

export interface GetOnChainListingFull {
  blockchainQuery: GetOnChainListingFull_blockchainQuery | null;
}

export interface GetOnChainListingFullVariables {
  input: GetOnChainListingInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMarketData
// ====================================================

export interface GetMarketData_cbsa_resources {
  __typename: "Resource";
  resourceType: ResourceType | null;
  url: string | null;
  textContent: string | null;
}

export interface GetMarketData_cbsa_yearlySummaries_medianHouseholdIncome_values {
  __typename: "CbsaYearlySummaryEntry";
  previousYearValue: Decimal | null;
  value: Decimal | null;
  year: number;
  yearOverYearChange: Decimal | null;
}

export interface GetMarketData_cbsa_yearlySummaries_medianHouseholdIncome {
  __typename: "CbsaYearlySummaryItemValues";
  values: GetMarketData_cbsa_yearlySummaries_medianHouseholdIncome_values[] | null;
}

export interface GetMarketData_cbsa_yearlySummaries_population_values {
  __typename: "CbsaYearlySummaryEntry";
  previousYearValue: Decimal | null;
  value: Decimal | null;
  year: number;
  yearOverYearChange: Decimal | null;
}

export interface GetMarketData_cbsa_yearlySummaries_population {
  __typename: "CbsaYearlySummaryItemValues";
  values: GetMarketData_cbsa_yearlySummaries_population_values[] | null;
}

export interface GetMarketData_cbsa_yearlySummaries_unemploymentRate_values {
  __typename: "CbsaYearlySummaryEntry";
  previousYearValue: Decimal | null;
  value: Decimal | null;
  year: number;
  yearOverYearChange: Decimal | null;
}

export interface GetMarketData_cbsa_yearlySummaries_unemploymentRate {
  __typename: "CbsaYearlySummaryItemValues";
  values: GetMarketData_cbsa_yearlySummaries_unemploymentRate_values[] | null;
}

export interface GetMarketData_cbsa_yearlySummaries {
  __typename: "CbsaYearlySummary";
  medianHouseholdIncome: GetMarketData_cbsa_yearlySummaries_medianHouseholdIncome | null;
  population: GetMarketData_cbsa_yearlySummaries_population | null;
  unemploymentRate: GetMarketData_cbsa_yearlySummaries_unemploymentRate | null;
}

export interface GetMarketData_cbsa {
  __typename: "Cbsa";
  cbsaCode: number;
  displayName: string | null;
  latitude: Decimal | null;
  longitude: Decimal | null;
  name: string | null;
  resources: GetMarketData_cbsa_resources[] | null;
  stateCode: string | null;
  yearlySummaries: GetMarketData_cbsa_yearlySummaries[] | null;
}

export interface GetMarketData {
  cbsa: GetMarketData_cbsa | null;
}

export interface GetMarketDataVariables {
  input: CbsaInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OriginalPropertyManagement
// ====================================================

export interface OriginalPropertyManagement_propertyManagementOptions {
  __typename: "PropertyManagementOption";
  displayName: string | null;
  logoUrl: string | null;
  websiteUrl: string | null;
  isCurrent: boolean;
}

export interface OriginalPropertyManagement {
  propertyManagementOptions: OriginalPropertyManagement_propertyManagementOptions[] | null;
}

export interface OriginalPropertyManagementVariables {
  input: PropertyManagementOptionInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMetadata
// ====================================================

export interface GetMetadata_blockchainQuery_listingMetadata {
  __typename: "ListingMetadata";
  recentlySold: boolean | null;
}

export interface GetMetadata_blockchainQuery {
  __typename: "BlockchainQuery";
  listingMetadata: GetMetadata_blockchainQuery_listingMetadata | null;
}

export interface GetMetadata {
  blockchainQuery: GetMetadata_blockchainQuery | null;
}

export interface GetMetadataVariables {
  input: MetadataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOnChainListings
// ====================================================

export interface GetOnChainListings_blockchainQuery_onChainListings {
  __typename: "OnChainListing";
  smartContractCryptoAddress: string | null;
  tokenId: string | null;
  cfsListingId: number | null;
  description: string | null;
  mainImageUrl: string | null;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  market: string | null;
  yearBuilt: number | null;
  bedrooms: Decimal | null;
  bathrooms: Decimal | null;
  squareFeet: number | null;
  lotSize: number | null;
  imageUrls: (string | null)[] | null;
  listPrice: Decimal | null;
  status: OnChainListingStatus;
  id: number;
  isRenovated: boolean | null;
  propertyType: string;
}

export interface GetOnChainListings_blockchainQuery {
  __typename: "BlockchainQuery";
  onChainListings: (GetOnChainListings_blockchainQuery_onChainListings | null)[] | null;
}

export interface GetOnChainListings {
  blockchainQuery: GetOnChainListings_blockchainQuery | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AcknowledgeDocuments
// ====================================================

export interface AcknowledgeDocuments_blockchainMutation_acknowledgeDocuments {
  __typename: "BlockchainResultType";
  httpStatusCode: HttpStatusCode;
}

export interface AcknowledgeDocuments_blockchainMutation {
  __typename: "BlockchainMutation";
  acknowledgeDocuments: AcknowledgeDocuments_blockchainMutation_acknowledgeDocuments | null;
}

export interface AcknowledgeDocuments {
  blockchainMutation: AcknowledgeDocuments_blockchainMutation | null;
}

export interface AcknowledgeDocumentsVariables {
  input: AcknowledgeDocumentsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetDocAckDate
// ====================================================

export interface GetDocAckDate_blockchainQuery_wallet {
  __typename: "Wallet";
  documentAcknowledgmentDate: DateTime | null;
}

export interface GetDocAckDate_blockchainQuery {
  __typename: "BlockchainQuery";
  wallet: GetDocAckDate_blockchainQuery_wallet | null;
}

export interface GetDocAckDate {
  blockchainQuery: GetDocAckDate_blockchainQuery | null;
}

export interface GetDocAckDateVariables {
  input: WalletInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ClearIdVerification
// ====================================================

export interface ClearIdVerification_blockchainMutation_clearIdVerification {
  __typename: "BlockchainResultType";
  httpStatusCode: HttpStatusCode;
  resultMessage: string | null;
}

export interface ClearIdVerification_blockchainMutation {
  __typename: "BlockchainMutation";
  clearIdVerification: ClearIdVerification_blockchainMutation_clearIdVerification | null;
}

export interface ClearIdVerification {
  blockchainMutation: ClearIdVerification_blockchainMutation | null;
}

export interface ClearIdVerificationVariables {
  input?: ClearIdVerificationInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetIdVerificationStatus
// ====================================================

export interface GetIdVerificationStatus_blockchainQuery_idVerification {
  __typename: "IdVerification";
  status: OnChainIdVerificationStatus;
  idVerificationUrl: string | null;
}

export interface GetIdVerificationStatus_blockchainQuery {
  __typename: "BlockchainQuery";
  idVerification: GetIdVerificationStatus_blockchainQuery_idVerification | null;
}

export interface GetIdVerificationStatus {
  blockchainQuery: GetIdVerificationStatus_blockchainQuery | null;
}

export interface GetIdVerificationStatusVariables {
  input: IdVerificationStatusInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: StartIdVerification
// ====================================================

export interface StartIdVerification_blockchainMutation_startIdVerification {
  __typename: "IdVerificationInitiationResultType";
  idVerificationUrl: string | null;
}

export interface StartIdVerification_blockchainMutation {
  __typename: "BlockchainMutation";
  startIdVerification: StartIdVerification_blockchainMutation_startIdVerification | null;
}

export interface StartIdVerification {
  blockchainMutation: StartIdVerification_blockchainMutation | null;
}

export interface StartIdVerificationVariables {
  input: InitiateIdVerificationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ConnectWalletForProfile
// ====================================================

export interface ConnectWalletForProfile_blockchainMutation_connectWallet {
  __typename: "ConnectWalletResult";
  anonymousIconOption: OnChainAnonymousIconOption | null;
}

export interface ConnectWalletForProfile_blockchainMutation {
  __typename: "BlockchainMutation";
  connectWallet: ConnectWalletForProfile_blockchainMutation_connectWallet | null;
}

export interface ConnectWalletForProfile {
  blockchainMutation: ConnectWalletForProfile_blockchainMutation | null;
}

export interface ConnectWalletForProfileVariables {
  input: WalletInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetWalletForProfile
// ====================================================

export interface GetWalletForProfile_blockchainQuery_wallet {
  __typename: "Wallet";
  cryptoAddress: string;
  idVerificationStatus: OnChainIdVerificationStatus | null;
  documentAcknowledgmentDate: DateTime | null;
  firstName: string | null;
  lastName: string | null;
  anonymousIcon: OnChainAnonymousIconOption;
}

export interface GetWalletForProfile_blockchainQuery {
  __typename: "BlockchainQuery";
  wallet: GetWalletForProfile_blockchainQuery_wallet | null;
}

export interface GetWalletForProfile {
  blockchainQuery: GetWalletForProfile_blockchainQuery | null;
}

export interface GetWalletForProfileVariables {
  input: WalletInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum CallerRole {
  Broker = "Broker",
  Buyer = "Buyer",
  BuyerBroker = "BuyerBroker",
  Roofstock = "Roofstock",
  RoofstockListingAgent = "RoofstockListingAgent",
  Seller = "Seller",
  TitleCompany = "TitleCompany",
}

export enum ContentStatus {
  Archived = "Archived",
  Deleted = "Deleted",
  Draft = "Draft",
  Published = "Published",
  Unspecified = "Unspecified",
}

export enum ContentType {
  Faq = "Faq",
  Glossary = "Glossary",
  Invite = "Invite",
  LennarFaq = "LennarFaq",
  News = "News",
  Press = "Press",
  RoofstockOnchainFaq = "RoofstockOnchainFaq",
  RoofstockOneFaq = "RoofstockOneFaq",
  Unspecified = "Unspecified",
}

export enum EntityType {
  Account = "Account",
  AcquisitionTransaction = "AcquisitionTransaction",
  Application = "Application",
  Asset = "Asset",
  Augmentation = "Augmentation",
  Bpo = "Bpo",
  Buybox = "Buybox",
  Cart = "Cart",
  Cbsa = "Cbsa",
  CbsaRentalSummary = "CbsaRentalSummary",
  Certification = "Certification",
  CfsTransaction = "CfsTransaction",
  CommunicationMessage = "CommunicationMessage",
  Comp = "Comp",
  Contact = "Contact",
  DividendReinvestmentProgram = "DividendReinvestmentProgram",
  DividendStatementSet = "DividendStatementSet",
  EntityRole = "EntityRole",
  EntityView = "EntityView",
  EnumValue = "EnumValue",
  Environment = "Environment",
  File = "File",
  Fund = "Fund",
  FundAsset = "FundAsset",
  FundInvestment = "FundInvestment",
  FundInvestmentAccount = "FundInvestmentAccount",
  FundInvestmentIndividual = "FundInvestmentIndividual",
  FundInvestmentVerificationTask = "FundInvestmentVerificationTask",
  GlobalProperty = "GlobalProperty",
  GlobalPropertyUnit = "GlobalPropertyUnit",
  Grid = "Grid",
  Hoa = "Hoa",
  Inspection = "Inspection",
  Instance = "Instance",
  InternalAcquisitionTransaction = "InternalAcquisitionTransaction",
  InvestmentStrategy = "InvestmentStrategy",
  InvestmentStrategyGoal = "InvestmentStrategyGoal",
  InvestmentStrategyStakeholder = "InvestmentStrategyStakeholder",
  InvestorProfileChecklist = "InvestorProfileChecklist",
  Job = "Job",
  Leasing = "Leasing",
  LeasingPerson = "LeasingPerson",
  LeasingProperty = "LeasingProperty",
  LeasingRentalLeadActivity = "LeasingRentalLeadActivity",
  LeasingRentalListing = "LeasingRentalListing",
  LeasingRentlyEmailInquiry = "LeasingRentlyEmailInquiry",
  LeasingRentlyPhoneInquiry = "LeasingRentlyPhoneInquiry",
  LeasingUnit = "LeasingUnit",
  Listing = "Listing",
  ListingAddendum = "ListingAddendum",
  ListingGroup = "ListingGroup",
  Market = "Market",
  MlsDisposition = "MlsDisposition",
  MlsEstimate = "MlsEstimate",
  MlsListing = "MlsListing",
  MlsListingPlus = "MlsListingPlus",
  MlsReso = "MlsReso",
  MlsSource = "MlsSource",
  MlsSync = "MlsSync",
  NinetyMillionHomes = "NinetyMillionHomes",
  None = "None",
  NorthCapital = "NorthCapital",
  Offer = "Offer",
  Offering = "Offering",
  OfferingOrder = "OfferingOrder",
  OfferingRecurringInvestment = "OfferingRecurringInvestment",
  Opportunity = "Opportunity",
  Order = "Order",
  Payment = "Payment",
  PaymentMethod = "PaymentMethod",
  Portfolio = "Portfolio",
  PortfolioBid = "PortfolioBid",
  PortfolioTransaction = "PortfolioTransaction",
  PreApprovalLetter = "PreApprovalLetter",
  Property = "Property",
  PropertyFinancialStage = "PropertyFinancialStage",
  PropertyHOA = "PropertyHOA",
  PropertyManager = "PropertyManager",
  PropertyManagerMarket = "PropertyManagerMarket",
  PropertyUnit = "PropertyUnit",
  PropertyUnitLease = "PropertyUnitLease",
  PropertyValuation = "PropertyValuation",
  Rehab = "Rehab",
  Reso = "Reso",
  RoofstockOneYardiProperty = "RoofstockOneYardiProperty",
  Salesforce = "Salesforce",
  ShareClass = "ShareClass",
  State = "State",
  Suitability = "Suitability",
  Supply = "Supply",
  SupplyGroup = "SupplyGroup",
  SupplyLead = "SupplyLead",
  SupplyPlus = "SupplyPlus",
  SupplyUnit = "SupplyUnit",
  Tape = "Tape",
  TapeProperty = "TapeProperty",
  TapePropertyGroup = "TapePropertyGroup",
  Task = "Task",
  Title = "Title",
  Transaction = "Transaction",
  TransactionForPortfolioTransaction = "TransactionForPortfolioTransaction",
  Underwriting = "Underwriting",
  UnderwritingRentComps = "UnderwritingRentComps",
  UnderwritingSaleComps = "UnderwritingSaleComps",
  ValueSet = "ValueSet",
  WebJob = "WebJob",
}

export enum HttpStatusCode {
  Accepted = "Accepted",
  AlreadyReported = "AlreadyReported",
  Ambiguous = "Ambiguous",
  BadGateway = "BadGateway",
  BadRequest = "BadRequest",
  Conflict = "Conflict",
  Continue = "Continue",
  Created = "Created",
  EarlyHints = "EarlyHints",
  ExpectationFailed = "ExpectationFailed",
  FailedDependency = "FailedDependency",
  Forbidden = "Forbidden",
  GatewayTimeout = "GatewayTimeout",
  Gone = "Gone",
  HttpVersionNotSupported = "HttpVersionNotSupported",
  IMUsed = "IMUsed",
  InsufficientStorage = "InsufficientStorage",
  InternalServerError = "InternalServerError",
  LengthRequired = "LengthRequired",
  Locked = "Locked",
  LoopDetected = "LoopDetected",
  MethodNotAllowed = "MethodNotAllowed",
  MisdirectedRequest = "MisdirectedRequest",
  Moved = "Moved",
  MultiStatus = "MultiStatus",
  NetworkAuthenticationRequired = "NetworkAuthenticationRequired",
  NoContent = "NoContent",
  NonAuthoritativeInformation = "NonAuthoritativeInformation",
  NotAcceptable = "NotAcceptable",
  NotExtended = "NotExtended",
  NotFound = "NotFound",
  NotImplemented = "NotImplemented",
  NotModified = "NotModified",
  OK = "OK",
  PartialContent = "PartialContent",
  PaymentRequired = "PaymentRequired",
  PermanentRedirect = "PermanentRedirect",
  PreconditionFailed = "PreconditionFailed",
  PreconditionRequired = "PreconditionRequired",
  Processing = "Processing",
  ProxyAuthenticationRequired = "ProxyAuthenticationRequired",
  Redirect = "Redirect",
  RedirectMethod = "RedirectMethod",
  RequestEntityTooLarge = "RequestEntityTooLarge",
  RequestHeaderFieldsTooLarge = "RequestHeaderFieldsTooLarge",
  RequestTimeout = "RequestTimeout",
  RequestUriTooLong = "RequestUriTooLong",
  RequestedRangeNotSatisfiable = "RequestedRangeNotSatisfiable",
  ResetContent = "ResetContent",
  ServiceUnavailable = "ServiceUnavailable",
  SwitchingProtocols = "SwitchingProtocols",
  TemporaryRedirect = "TemporaryRedirect",
  TooManyRequests = "TooManyRequests",
  Unauthorized = "Unauthorized",
  UnavailableForLegalReasons = "UnavailableForLegalReasons",
  UnprocessableEntity = "UnprocessableEntity",
  UnsupportedMediaType = "UnsupportedMediaType",
  Unused = "Unused",
  UpgradeRequired = "UpgradeRequired",
  UseProxy = "UseProxy",
  VariantAlsoNegotiates = "VariantAlsoNegotiates",
}

export enum OnChainAnonymousIconOption {
  BEAVER = "BEAVER",
  CAPYBARA = "CAPYBARA",
  ELK = "ELK",
  GORILLA = "GORILLA",
  HEDGEHOG = "HEDGEHOG",
  KIWI = "KIWI",
  LLAMA = "LLAMA",
  MEERKAT = "MEERKAT",
  OCTOPUS = "OCTOPUS",
  OWL = "OWL",
  PENGUIN = "PENGUIN",
  RABBIT = "RABBIT",
  UNSPECIFIED = "UNSPECIFIED",
}

export enum OnChainIdVerificationStatus {
  ERROR = "ERROR",
  IN_PROGRESS = "IN_PROGRESS",
  REJECTED = "REJECTED",
  STARTED = "STARTED",
  TIMEOUT = "TIMEOUT",
  UNSPECIFIED = "UNSPECIFIED",
  VERIFIED = "VERIFIED",
}

export enum OnChainListingStatus {
  ACTIVE = "ACTIVE",
  COMING_SOON = "COMING_SOON",
  INACTIVE = "INACTIVE",
  UNSPECIFIED = "UNSPECIFIED",
}

export enum OrderDirection {
  ASC = "ASC",
  DESC = "DESC",
}

export enum ResourceType {
  AccountAddendumToPropertyManagementAgreement = "AccountAddendumToPropertyManagementAgreement",
  AccountCCR = "AccountCCR",
  AccountClosingDocuments = "AccountClosingDocuments",
  AccountClosingWiringInstructions = "AccountClosingWiringInstructions",
  AccountCompliance = "AccountCompliance",
  AccountDocument = "AccountDocument",
  AccountEMDWiringInstructions = "AccountEMDWiringInstructions",
  AccountEmploymentContract = "AccountEmploymentContract",
  AccountFeeStructure = "AccountFeeStructure",
  AccountHistoricalTapes = "AccountHistoricalTapes",
  AccountLeaseDocumentTemplate = "AccountLeaseDocumentTemplate",
  AccountLenderPreQualificationLetter = "AccountLenderPreQualificationLetter",
  AccountMasterServiceAgreement = "AccountMasterServiceAgreement",
  AccountMinimumStandards = "AccountMinimumStandards",
  AccountNonDisclosureAgreement = "AccountNonDisclosureAgreement",
  AccountOrganizationDetails = "AccountOrganizationDetails",
  AccountOwnerOnboardingProcess = "AccountOwnerOnboardingProcess",
  AccountPaymentHistory = "AccountPaymentHistory",
  AccountPreApprovalLetter = "AccountPreApprovalLetter",
  AccountProofOfFunds = "AccountProofOfFunds",
  AccountPropertyManagementAgreement = "AccountPropertyManagementAgreement",
  AccountPurchaseSaleAgreement = "AccountPurchaseSaleAgreement",
  AccountRoofstockServiceAgreement = "AccountRoofstockServiceAgreement",
  AccountServiceLevelAgreement = "AccountServiceLevelAgreement",
  BoxPropertyDiligence = "BoxPropertyDiligence",
  CertificationDocument = "CertificationDocument",
  CertificationMessageCenter = "CertificationMessageCenter",
  ContentNewsImage = "ContentNewsImage",
  ContentPressImage = "ContentPressImage",
  DiligenceVaultClosingCostsEstimate = "DiligenceVaultClosingCostsEstimate",
  DiligenceVaultHoaCovenantsCodesAndRestrictions = "DiligenceVaultHoaCovenantsCodesAndRestrictions",
  DiligenceVaultHomeDisclosure = "DiligenceVaultHomeDisclosure",
  DiligenceVaultLease = "DiligenceVaultLease",
  DiligenceVaultLeaseAbstract = "DiligenceVaultLeaseAbstract",
  DiligenceVaultLedger = "DiligenceVaultLedger",
  DiligenceVaultMarketRentAnalysis = "DiligenceVaultMarketRentAnalysis",
  DiligenceVaultPaymentHistory = "DiligenceVaultPaymentHistory",
  DiligenceVaultPreliminaryInsurance = "DiligenceVaultPreliminaryInsurance",
  DiligenceVaultPropertyInspectionReport = "DiligenceVaultPropertyInspectionReport",
  DiligenceVaultPropertyValuation = "DiligenceVaultPropertyValuation",
  DiligenceVaultRedactedLease = "DiligenceVaultRedactedLease",
  DiligenceVaultStateDisclosure = "DiligenceVaultStateDisclosure",
  DiligenceVaultTenantPurchaseOption = "DiligenceVaultTenantPurchaseOption",
  DiligenceVaultTitleReportAndPreliminaryTitleCommitment = "DiligenceVaultTitleReportAndPreliminaryTitleCommitment",
  DividendStatementSetData = "DividendStatementSetData",
  ESignCO = "ESignCO",
  ESignCPSA = "ESignCPSA",
  ESignCSLA = "ESignCSLA",
  ESignLennarPsaAgreement = "ESignLennarPsaAgreement",
  ESignMLSOffer = "ESignMLSOffer",
  ESignPSA = "ESignPSA",
  ESignRsOneSubscriptionAgreement = "ESignRsOneSubscriptionAgreement",
  ESignSLA = "ESignSLA",
  ESignSellerDisclosures = "ESignSellerDisclosures",
  ESignTO = "ESignTO",
  ESignTxnAmendment = "ESignTxnAmendment",
  FundInvestmentAccreditationSignedDoc = "FundInvestmentAccreditationSignedDoc",
  FundInvestmentSubscriptionSignedDoc = "FundInvestmentSubscriptionSignedDoc",
  FundMarketContent = "FundMarketContent",
  FundPrivatePlacementMemorandum = "FundPrivatePlacementMemorandum",
  FundSubscriptionDocument = "FundSubscriptionDocument",
  Init = "Init",
  InvestorAccountDocument = "InvestorAccountDocument",
  InvestorAccreditationDocument = "InvestorAccreditationDocument",
  InvestorIraAccountDocument = "InvestorIraAccountDocument",
  LeadHOA = "LeadHOA",
  LeadInspectionReport = "LeadInspectionReport",
  LeadLease = "LeadLease",
  LeadLedger = "LeadLedger",
  LeadPhotos = "LeadPhotos",
  LeadSellerDisclosures = "LeadSellerDisclosures",
  MarketAreaBrokerDocumentation = "MarketAreaBrokerDocumentation",
  MarketAreaDisclosures = "MarketAreaDisclosures",
  MarketAreaPurchaseSaleAgreementTemplate = "MarketAreaPurchaseSaleAgreementTemplate",
  MarketContentBlob = "MarketContentBlob",
  MarketContentHeadline = "MarketContentHeadline",
  MarketDidYouKnow = "MarketDidYouKnow",
  MarketDislikes = "MarketDislikes",
  MarketFootnotes = "MarketFootnotes",
  MarketInsights = "MarketInsights",
  MarketLikes = "MarketLikes",
  MarketMetaDescription = "MarketMetaDescription",
  MarketMetaKeywords = "MarketMetaKeywords",
  MarketOnchainArtSplashImageLink = "MarketOnchainArtSplashImageLink",
  MarketOnchainOverview = "MarketOnchainOverview",
  MarketOverview = "MarketOverview",
  MarketPageTitle = "MarketPageTitle",
  MarketResearchReportLink = "MarketResearchReportLink",
  MarketSplashImageLink = "MarketSplashImageLink",
  MarketVideoLink = "MarketVideoLink",
  MlsDispositionBpo = "MlsDispositionBpo",
  MlsDispositionBrokerReferralAgreement = "MlsDispositionBrokerReferralAgreement",
  MlsDispositionDisclosure = "MlsDispositionDisclosure",
  MlsDispositionHoaCcr = "MlsDispositionHoaCcr",
  MlsDispositionListingAgreement = "MlsDispositionListingAgreement",
  MlsDispositionOther = "MlsDispositionOther",
  MlsDispositionPurchaseSaleAgreement = "MlsDispositionPurchaseSaleAgreement",
  MlsDispositionRentRoll = "MlsDispositionRentRoll",
  MlsDispositionSellerEntityDocs = "MlsDispositionSellerEntityDocs",
  OfferPreApprovalLetter = "OfferPreApprovalLetter",
  OfferProofOfFunds = "OfferProofOfFunds",
  OfferPurchaseSaleAgreement = "OfferPurchaseSaleAgreement",
  OnChainPublicDocument = "OnChainPublicDocument",
  OpportunitySSS = "OpportunitySSS",
  PMAppUseAvailabilityToClientBase = "PMAppUseAvailabilityToClientBase",
  PMCoverageArea = "PMCoverageArea",
  PMDataSharing = "PMDataSharing",
  PMDocumentRequestList = "PMDocumentRequestList",
  PMLeaseTemplate = "PMLeaseTemplate",
  PMLogo = "PMLogo",
  PMMasterPMAgreement = "PMMasterPMAgreement",
  PMMasterPMAgreementInsuranceProgram = "PMMasterPMAgreementInsuranceProgram",
  PMOngoingDataSharing = "PMOngoingDataSharing",
  PMOther = "PMOther",
  PMPortfolioPerformance = "PMPortfolioPerformance",
  PMQuestionnaire = "PMQuestionnaire",
  PMResidentTenantOnboardingChecklist = "PMResidentTenantOnboardingChecklist",
  PMSLA = "PMSLA",
  PMSamplePMAgreement = "PMSamplePMAgreement",
  PMSlaTermination = "PMSlaTermination",
  PMStandardAddendum = "PMStandardAddendum",
  PMTenantApplication = "PMTenantApplication",
  PMTenantScreeningGuidelines = "PMTenantScreeningGuidelines",
  PartnerPageContent = "PartnerPageContent",
  PlatinumTurnkey = "PlatinumTurnkey",
  PortfolioBidProposalAttachments = "PortfolioBidProposalAttachments",
  PortfolioContentSectionPhoto = "PortfolioContentSectionPhoto",
  PortfolioDescription = "PortfolioDescription",
  PortfolioDocument = "PortfolioDocument",
  PortfolioFloorPlanPhoto = "PortfolioFloorPlanPhoto",
  PortfolioPhoto = "PortfolioPhoto",
  PortfolioTransactionAddendumToContract = "PortfolioTransactionAddendumToContract",
  PortfolioTransactionAmendmentToContract = "PortfolioTransactionAmendmentToContract",
  PortfolioTransactionCancellationOfContract = "PortfolioTransactionCancellationOfContract",
  PortfolioTransactionClosingStatement = "PortfolioTransactionClosingStatement",
  PortfolioTransactionEmd = "PortfolioTransactionEmd",
  PortfolioTransactionEstimatedClosingStatement = "PortfolioTransactionEstimatedClosingStatement",
  PortfolioTransactionExtensions = "PortfolioTransactionExtensions",
  PortfolioTransactionLetterOfIntent = "PortfolioTransactionLetterOfIntent",
  PortfolioTransactionMessageCenter = "PortfolioTransactionMessageCenter",
  PortfolioTransactionOther = "PortfolioTransactionOther",
  PortfolioTransactionPreApprovalLetter = "PortfolioTransactionPreApprovalLetter",
  PortfolioTransactionProofOfFunds = "PortfolioTransactionProofOfFunds",
  PortfolioTransactionPurchaseSaleAgreement = "PortfolioTransactionPurchaseSaleAgreement",
  PortfolioTransactionRentRoll = "PortfolioTransactionRentRoll",
  PortfolioTransactionSellerBulkDocumentDrop = "PortfolioTransactionSellerBulkDocumentDrop",
  PortfolioTransactionWholePortfolioAsZip = "PortfolioTransactionWholePortfolioAsZip",
  PortfolioTransactionWiringInstructions = "PortfolioTransactionWiringInstructions",
  PortfolioTransactionWorkingGroup = "PortfolioTransactionWorkingGroup",
  PortfolioVault = "PortfolioVault",
  Property3DTour = "Property3DTour",
  PropertyAudio = "PropertyAudio",
  PropertyConstructionHistory = "PropertyConstructionHistory",
  PropertyConstructionWarranty = "PropertyConstructionWarranty",
  PropertyCorporateArticlesOfOrganization = "PropertyCorporateArticlesOfOrganization",
  PropertyCreditAndBackground = "PropertyCreditAndBackground",
  PropertyDiligenceVaultDocuments = "PropertyDiligenceVaultDocuments",
  PropertyFloorPlan = "PropertyFloorPlan",
  PropertyHoaSearchEstoppel = "PropertyHoaSearchEstoppel",
  PropertyHoaSearchHoaLetter = "PropertyHoaSearchHoaLetter",
  PropertyLeaseDisclosures = "PropertyLeaseDisclosures",
  PropertyLienSearch = "PropertyLienSearch",
  PropertyListingAddendum = "PropertyListingAddendum",
  PropertyMarketingListingAgentPhotos = "PropertyMarketingListingAgentPhotos",
  PropertyMarketingRentGuarantee = "PropertyMarketingRentGuarantee",
  PropertyMarketingSellerPhotos = "PropertyMarketingSellerPhotos",
  PropertyMoveInMoveOutForm = "PropertyMoveInMoveOutForm",
  PropertyOpenWorkOrders = "PropertyOpenWorkOrders",
  PropertyPhoto = "PropertyPhoto",
  PropertyRentGuarantee = "PropertyRentGuarantee",
  PropertyRentGuaranteeMasterLease = "PropertyRentGuaranteeMasterLease",
  PropertyRoofWarranty = "PropertyRoofWarranty",
  PropertyRoofstockAppraisalReport = "PropertyRoofstockAppraisalReport",
  PropertySection8 = "PropertySection8",
  PropertySellerOwnerTitlePolicy = "PropertySellerOwnerTitlePolicy",
  PropertyTape = "PropertyTape",
  PropertyTax = "PropertyTax",
  PropertyTenantApplication = "PropertyTenantApplication",
  PropertyTitleSearchWithComment = "PropertyTitleSearchWithComment",
  PropertyValuationPhotos = "PropertyValuationPhotos",
  PropertyWorkOrderHistory = "PropertyWorkOrderHistory",
  SellerDisclosures = "SellerDisclosures",
  ShareClassHomepageImage = "ShareClassHomepageImage",
  ShareClassInvestmentSupplement = "ShareClassInvestmentSupplement",
  ShareClassMainImage = "ShareClassMainImage",
  ShortTermRentalManagementAgreement = "ShortTermRentalManagementAgreement",
  ShortTermRentalRevenueProjection = "ShortTermRentalRevenueProjection",
  SupplyListingImages = "SupplyListingImages",
  SystemFeaturedPropertyReasons = "SystemFeaturedPropertyReasons",
  TeamPageContent = "TeamPageContent",
  TransactionAddendumToContract = "TransactionAddendumToContract",
  TransactionAmendmentToContract = "TransactionAmendmentToContract",
  TransactionAppraisalPacket = "TransactionAppraisalPacket",
  TransactionBuyerInspectionReport = "TransactionBuyerInspectionReport",
  TransactionCalendar = "TransactionCalendar",
  TransactionCancellationOfContract = "TransactionCancellationOfContract",
  TransactionCertificateOfInsurance = "TransactionCertificateOfInsurance",
  TransactionClosingStatement = "TransactionClosingStatement",
  TransactionDeed = "TransactionDeed",
  TransactionDisclosure = "TransactionDisclosure",
  TransactionDocument = "TransactionDocument",
  TransactionEMDReceipt = "TransactionEMDReceipt",
  TransactionEmd = "TransactionEmd",
  TransactionEmdReceiptAndExchangeDeposit = "TransactionEmdReceiptAndExchangeDeposit",
  TransactionEnergyPerformanceCertificate = "TransactionEnergyPerformanceCertificate",
  TransactionEstimatedClosingStatement = "TransactionEstimatedClosingStatement",
  TransactionFinalClosingStatement = "TransactionFinalClosingStatement",
  TransactionFinancingAppraisal = "TransactionFinancingAppraisal",
  TransactionGrantDeed = "TransactionGrantDeed",
  TransactionHOA = "TransactionHOA",
  TransactionLandTransactionTax = "TransactionLandTransactionTax",
  TransactionLease = "TransactionLease",
  TransactionLeaseholdDocuments = "TransactionLeaseholdDocuments",
  TransactionLedger = "TransactionLedger",
  TransactionLetterOfIntent = "TransactionLetterOfIntent",
  TransactionListingAddendum = "TransactionListingAddendum",
  TransactionMemorandumOfSaleAndContract = "TransactionMemorandumOfSaleAndContract",
  TransactionMessageCenter = "TransactionMessageCenter",
  TransactionNewPropertyManagerDocumentation = "TransactionNewPropertyManagerDocumentation",
  TransactionOther = "TransactionOther",
  TransactionOwnersTitlePolicy = "TransactionOwnersTitlePolicy",
  TransactionPaymentHistory = "TransactionPaymentHistory",
  TransactionPreApprovalLetter = "TransactionPreApprovalLetter",
  TransactionProofOfFunds = "TransactionProofOfFunds",
  TransactionPropertyInsurance = "TransactionPropertyInsurance",
  TransactionPurchaseSaleAgreement = "TransactionPurchaseSaleAgreement",
  TransactionRentRoll = "TransactionRentRoll",
  TransactionReportOnTitle = "TransactionReportOnTitle",
  TransactionSellerEntityDocs = "TransactionSellerEntityDocs",
  TransactionSolicitorDocuments = "TransactionSolicitorDocuments",
  TransactionSurveyAndValuationDocuments = "TransactionSurveyAndValuationDocuments",
  TransactionTR1AndDeed = "TransactionTR1AndDeed",
  TransactionTax = "TransactionTax",
  TransactionTaxCertificate = "TransactionTaxCertificate",
  TransactionTenancyAgreementsAndRentRolls = "TransactionTenancyAgreementsAndRentRolls",
  TransactionTitleCommitment = "TransactionTitleCommitment",
  TransactionTitleSearch = "TransactionTitleSearch",
  TransactionWiringInstructions = "TransactionWiringInstructions",
  TransactionWorkingGroupList = "TransactionWorkingGroupList",
  Unspecified = "Unspecified",
}

export enum Role {
  AccountManager = "AccountManager",
  AccountRole = "AccountRole",
  AccountType = "AccountType",
  AccreditedInvestor = "AccreditedInvestor",
  AcquisitionPartner = "AcquisitionPartner",
  AcquisitionTransactionCoordinator = "AcquisitionTransactionCoordinator",
  Administrator = "Administrator",
  Agent = "Agent",
  AgreementSigner = "AgreementSigner",
  AppAccessRentalGenome = "AppAccessRentalGenome",
  AppRole = "AppRole",
  AppraisalCompany = "AppraisalCompany",
  Broker = "Broker",
  BrokerAmbassador = "BrokerAmbassador",
  Buyer = "Buyer",
  BuyerBroker = "BuyerBroker",
  BuyerManagementAccount = "BuyerManagementAccount",
  BuyerPM = "BuyerPM",
  BuyerSigner = "BuyerSigner",
  CertifiedAgent = "CertifiedAgent",
  CertifiedAgentNetwork = "CertifiedAgentNetwork",
  CfsPropertiesExport = "CfsPropertiesExport",
  ClosingAttorney = "ClosingAttorney",
  CoListingAgent = "CoListingAgent",
  ContestantImn2016 = "ContestantImn2016",
  Customer = "Customer",
  EmdAccounting = "EmdAccounting",
  EscrowAccounting = "EscrowAccounting",
  EscrowAgent = "EscrowAgent",
  EscrowCompany = "EscrowCompany",
  ExchangeCompany_1031 = "ExchangeCompany_1031",
  FeatureRole = "FeatureRole",
  Fund = "Fund",
  FundInvestmentAccount = "FundInvestmentAccount",
  HOA = "HOA",
  Individual = "Individual",
  InspectionCompany = "InspectionCompany",
  Institution = "Institution",
  InsuranceCompany = "InsuranceCompany",
  InventoryOwner = "InventoryOwner",
  InvestmentManager = "InvestmentManager",
  InvestmentServiceCustomer = "InvestmentServiceCustomer",
  IraBroker = "IraBroker",
  LenderBroker = "LenderBroker",
  Mls = "Mls",
  MlsAggregator = "MlsAggregator",
  MlsListingAgent = "MlsListingAgent",
  NonAccreditedInvestor = "NonAccreditedInvestor",
  None = "None",
  Owner = "Owner",
  Partner = "Partner",
  PaymentVendor = "PaymentVendor",
  PermissionRole = "PermissionRole",
  Photographer = "Photographer",
  PlatinumSeller = "PlatinumSeller",
  PortfolioAdmin = "PortfolioAdmin",
  PortfolioAdvisor = "PortfolioAdvisor",
  PropertyManager = "PropertyManager",
  QualityControlUser = "QualityControlUser",
  Reader = "Reader",
  ReceiveAcquisitionBuyInspectionPMEmails = "ReceiveAcquisitionBuyInspectionPMEmails",
  ReceiveOfferEmails = "ReceiveOfferEmails",
  ReceivePortfolioOfferEmails = "ReceivePortfolioOfferEmails",
  ReferralBroker = "ReferralBroker",
  RegularUser = "RegularUser",
  RoofSavvy = "RoofSavvy",
  Roofstock = "Roofstock",
  RoofstockAccountCoordinator = "RoofstockAccountCoordinator",
  RoofstockAccounting = "RoofstockAccounting",
  RoofstockAcquisitionTransactionCoordinator = "RoofstockAcquisitionTransactionCoordinator",
  RoofstockAcquisitionTransactionCoordinatorManager = "RoofstockAcquisitionTransactionCoordinatorManager",
  RoofstockAdministrator = "RoofstockAdministrator",
  RoofstockAdvisor = "RoofstockAdvisor",
  RoofstockAdvisorManager = "RoofstockAdvisorManager",
  RoofstockAdvisorSuperUser = "RoofstockAdvisorSuperUser",
  RoofstockAnalyst = "RoofstockAnalyst",
  RoofstockAnalystSuperUser = "RoofstockAnalystSuperUser",
  RoofstockAppian = "RoofstockAppian",
  RoofstockBizDev = "RoofstockBizDev",
  RoofstockBizDevManager = "RoofstockBizDevManager",
  RoofstockBizDevSuperUser = "RoofstockBizDevSuperUser",
  RoofstockCertification = "RoofstockCertification",
  RoofstockCertificationManager = "RoofstockCertificationManager",
  RoofstockCertificationSuperUser = "RoofstockCertificationSuperUser",
  RoofstockCommunity = "RoofstockCommunity",
  RoofstockCommunityManager = "RoofstockCommunityManager",
  RoofstockCommunitySuperUser = "RoofstockCommunitySuperUser",
  RoofstockCustomerAdvocate = "RoofstockCustomerAdvocate",
  RoofstockCustomerAdvocateManager = "RoofstockCustomerAdvocateManager",
  RoofstockCustomerAdvocateSuperUser = "RoofstockCustomerAdvocateSuperUser",
  RoofstockDeveloper = "RoofstockDeveloper",
  RoofstockImpersonator = "RoofstockImpersonator",
  RoofstockInspectionProjectManager = "RoofstockInspectionProjectManager",
  RoofstockInventoryManagementProduct = "RoofstockInventoryManagementProduct",
  RoofstockListingAgent = "RoofstockListingAgent",
  RoofstockMarketing = "RoofstockMarketing",
  RoofstockMarketingManager = "RoofstockMarketingManager",
  RoofstockMarketingSuperUser = "RoofstockMarketingSuperUser",
  RoofstockOneRecordedInHubspot = "RoofstockOneRecordedInHubspot",
  RoofstockOneSuperUser = "RoofstockOneSuperUser",
  RoofstockPIIAccess = "RoofstockPIIAccess",
  RoofstockProjectManager = "RoofstockProjectManager",
  RoofstockRack = "RoofstockRack",
  RoofstockS3Dm = "RoofstockS3Dm",
  RoofstockS3Manager = "RoofstockS3Manager",
  RoofstockS3SuperUser = "RoofstockS3SuperUser",
  RoofstockSalesforceAccountOwner = "RoofstockSalesforceAccountOwner",
  RoofstockTransactionCoordinator = "RoofstockTransactionCoordinator",
  RoofstockTransactionCoordinatorManager = "RoofstockTransactionCoordinatorManager",
  RoofstockTransactionCoordinatorSuperUser = "RoofstockTransactionCoordinatorSuperUser",
  RoofstockUnderwriter = "RoofstockUnderwriter",
  RoofstockUserAdmin = "RoofstockUserAdmin",
  SelfAccreditedInvestor = "SelfAccreditedInvestor",
  SelfManageOffers = "SelfManageOffers",
  SelfManagePortfolioOffers = "SelfManagePortfolioOffers",
  Seller = "Seller",
  SellerCanEditListPrice = "SellerCanEditListPrice",
  SellerDiligenceContact = "SellerDiligenceContact",
  SellerManagementAccount = "SellerManagementAccount",
  SellerPM = "SellerPM",
  SellerSigner = "SellerSigner",
  ServiceProvider = "ServiceProvider",
  StakeholderAssignment = "StakeholderAssignment",
  TitleCompany = "TitleCompany",
  Underwriter = "Underwriter",
  UserRole = "UserRole",
  ViewAllCertificationDocuments = "ViewAllCertificationDocuments",
  Writer = "Writer",
}

export interface AcknowledgeDocumentsInput {
  documentAcknowledgeDate?: DateTime | null;
  walletAddress?: string | null;
}

export interface CbsaInput {
  cbsaCode?: string | null;
}

export interface ClearIdVerificationInput {
  walletAddress?: string | null;
}

export interface ContentsInput {
  title?: string | null;
  category?: string | null;
  status?: ContentStatus[] | null;
  type?: ContentType[] | null;
  paging?: PagingInput | null;
}

export interface GetOnChainListingInput {
  onChainListingId?: number | null;
  smartContractAddress?: string | null;
  tokenId?: string | null;
}

export interface IdVerificationStatusInput {
  walletAddress?: string | null;
}

export interface InitiateIdVerificationInput {
  emailAddress?: string | null;
  walletAddress?: string | null;
}

export interface MetadataInput {
  smartContractCryptoAddress?: string | null;
  tokenId?: string | null;
}

export interface OrderByInput {
  field?: string | null;
  direction?: OrderDirection | null;
}

export interface PagingInput {
  orderBy?: OrderByInput | null;
  pageNumber?: number | null;
  pageSize?: number | null;
}

export interface PropertyManagementOptionInput {
  propertyId: string;
  includeSelfManage: boolean;
  transactionId?: number | null;
}

export interface ResourceBlobsInput {
  callerRole: CallerRole;
  role?: Role | null;
  targetEntityType: EntityType;
  targetEntityId: string;
  includeEmptyFolder?: boolean | null;
  includeDiligenceDocuments?: boolean | null;
  pathPattern?: string | null;
  accessToken?: string | null;
  resourceType?: ResourceType[] | null;
  listingId?: number | null;
}

export interface WalletInput {
  walletAddress?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
