// cspell:ignore googletagmanager, mxpnls

import { PublicConfig, ServerConfig } from '@rs-app/@types';

import getConfig from 'next/config';

const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();

/*
 * serverConfig extends next/config serverRuntimeConfig
 */
const serverConfig: ServerConfig = Object.assign({}, serverRuntimeConfig);
// serverConfig.someSecret.someOther = `${serverConfig.someSecret}-extend`;

/*
 * publicConfig extends next/config publicRuntimeConfig
 */
const publicConfig: PublicConfig = Object.assign({}, publicRuntimeConfig);

if (!publicConfig.environmentName) {
  console.error('environmentName is undefined. Ensure environmentName is defined in appSettings file(s)');
}

publicConfig.routes = {
  root: `${publicConfig.app.basePath}`,
};

publicConfig.imagesFolder = `${publicConfig.app.basePath}/images`;
publicConfig.rsOneFontsFolder = `${publicConfig.app.basePath}/fonts/style.css`;

const nextApiUrl = publicConfig.rsConfig.endpoints.nextApiUrl;
publicConfig.rsConfig.endpoints.extended = {
  nextApi: {
    logoutUrl: `${nextApiUrl}/auth/logout`,
    reestablishUrl: `${nextApiUrl}/auth/reestablish`,
    webAnalyticsActionEventUrl: `${nextApiUrl}/WebAnalyticsActionEvent`,
    webAnalyticsPageViewUrl: `${nextApiUrl}/WebAnalyticsPageView`,
  },
};
publicConfig.cdnHost = 'https://roofstock-cdn4.azureedge.net';

publicConfig.discordUrl = 'https://discord.gg/RoofstockOnChain';
publicConfig.twitterUrl = 'https://twitter.com/rsonchain';

publicConfig.rsConfig.features.siteAnnouncement = {
  dismissedCookieName: 'SiteAnnouncementDismissed',
};

const googleMapApiUrl = 'https://maps.googleapis.com/maps/api/js';
const googleApiKey = publicConfig.google.apiKey;
publicConfig.google.mapUrl = `${googleMapApiUrl}?key=${googleApiKey}&v=3.exp&libraries=geometry,drawing,places`;

// publicConfig.appId = {
//   roofstock: 100,
//   one: 400,
//   lennar: 1000,
// };

/* cspell:disable */
publicConfig.contentSecurityPolicy = `
  default-src 'self' https:;
  script-src 'self' https: 'unsafe-eval' 'unsafe-inline' *.collect.igodigital.com *.pardot.com *.lennar.international www.googletagmanager.com *.abtasty.com cdnjs.cloudflare.com calendly.com *.mxpnl.com *.googleapis.com *.google-analytics.com *.googleadservices.com *.gstatic.com roofstock-cdn.azureedge.net roofstock-cdn4.azureedge.net *.hs-scripts.com *.hs-analytics.net fullstory.com *.fullstory.com us-autocomplete.api.smartystreets.com *.vimeocdn.com *.digicert.com *.google.com widget.intercom.io js.intercomcdn.com *.criteo.net *.criteo.com cdn.evgnet.com *.quora.com *.licdn.com *.cloudsponge.com d.impactradius-event.com *.cloudfront.net http://localhost:* fast.ssqt.io;
  font-src 'self' data: d3sbxpiag177w8.cloudfront.net fast.ssqt.io cdnjs.cloudflare.com fonts.googleapis.com fonts.gstatic.com themes.googleusercontent.com roofstock-cdn.azureedge.net roofstock-cdn4.azureedge.net js.intercomcdn.com *.cloudsponge.com surveys-static.survicate.com;
  img-src 'self' data: placehold.it *.chilipiper.com *.paypal.com b.stats.paypal.com www.greatplacetowork.com *.pardot.com lipis.github.io *.googleapis.com roofstock-cdn4.azureedge.net *.google-analytics.com maps.gstatic.com csi.gstatic.com *.google.com *.blob.core.windows.net roofstock-cdn.azureedge.net roofstock-cdn4.azureedge.net *.hubspot.com *.ggpht.com *.digicert.com js.intercomcdn.com static.intercomassets.com *.criteo.com *.quora.com *.licdn.com *.cloudsponge.com alb.reddit.com *.ojrq.net *.adsymptotic.com *.intercomcdn.com nova.collect.igodigital.com *.wpengine.com t.co analytics.twitter.com registry.walletconnect.com;
  frame-src 'self' https://cdn.plaid.com roofstock.chilipiper.com *.azureedge.net roofstock-cdn.azureedge.net roofstock-cdn4.azureedge.net *.paypal.com tst.kaptcha.com *.pardot.com *.blob.core.windows.net *.sharethis.com assets.braintreegateway.com calendly.com *.vimeo.com *.criteo.com *.docusign.net *.force.com *.salesforce.com www.google.com www.youtube.com my.matterport.com;
  media-src 'self' *.blob.core.windows.net *.vimeo.com js.intercomcdn.com roofstock-cdn.azureedge.net roofstock-cdn4.azureedge.net vod-progressive.akamaized.net api.cheddar.com *.cheddar.com cheddar.com cheddar-production.s3.us-east-1.amazonaws.com cheddar-production.s3.amazonaws.com *.amazonaws.com;
  style-src 'self' https: 'unsafe-inline' calendly.com use.fontawesome.com naver.github.io cdnjs.cloudflare.com fonts.googleapis.com maps.gstatic.com *.ssl.fastly.net *.sharethis.com *.cloudsponge.com http://localhost:*;
  connect-src 'self' https: wss://*.intercom.io local.roofstock.com:* cdnjs.cloudflare.com *.mixpanel.com maps.gstatic.com *.hubspot.com fullstory.com *.fullstory.com us-autocomplete.api.smartystreets.com api-iam.intercom.io *.intercom.io *.criteo.net roofstock.evergage.com *.cloudsponge.com roofstock.sjv.io *.abtasty.com *.adsymptotic.com *.roofstock.com http://localhost:* wss://www.walletlink.org/rpc wss://*.walletconnect.org;
  worker-src blob:;
`;
/* cspell:enable */

export { serverConfig, publicConfig };
