// cspell:ignore uetq etmc

import * as React from 'react';
import * as mixpanel from 'mixpanel-browser';

import { AppName, LogSegmentPageView, LogSegmentTrackEvent } from '../events/SegmentEvents';

import CryptoJS from 'crypto-js';
import { isServer } from '@rs-app/utils';
import { publicConfig } from '../config';

interface IEventProps {
  product: string;
  item: string;
  action: string;
  section: string;
  itemName: string;
  email?: string;
  orderId?: string | null;
  classId?: string | null;
  accountId?: string | null;
  offeringId?: string | null;
  redirectUrl?: string;
  regSourceName?: string | null;
  socialType?: string;
  otherMetadata?: string;
  regPhoneNumberRequired?: boolean;
}

interface ILogEventParams {
  email?: string;
  referrer?: string;
  regSourceName?: string | null;
  socialType?: string;
  allow_custom_scripts?: boolean;
  send_to?: string;
}

interface IPageEventProps {
  email?: string;
  application?: string;
  'Page Url'?: string;
  firstName?: string;
  lastName?: string;
  userId?: string;
  accountId?: string;
}

interface ILogImpactRadiusParams {
  orderId: string;
  eventId: string;
  customerEmail: string;
  customerId: string;
}

interface IEventTrackingHook {
  logEvent: (eventName: string, props: IEventProps | ILogEventParams) => void;
  logFormattedEvent: (props: IEventProps) => void;
  logMixpanelEvent: (event: string, props: IEventProps | ILogEventParams | IPageEventProps) => void;
  logGoogleAnalyticsEvent: (event: string, props: IEventProps | ILogEventParams) => void;
  logDoubleClickEvent: (event: string, props: IEventProps | ILogEventParams) => void;
  logImpactRadiusEvent: (options: ILogImpactRadiusParams) => void;
  logSegmentTrackEvent: (event: string, props: { app: AppName }) => void;
  logPageView: (url: string | null) => void;
}

const useEventTracking = (): IEventTrackingHook => {
  const logMixpanelEvent = React.useCallback(
    (event: string, props: IEventProps | ILogEventParams | IPageEventProps) => {
      const token = publicConfig.analytics.mixpanel.token;
      if (token) {
        mixpanel.init(token);

        if (!props) {
          props = {};
        }

        props['source'] = 'next-app';

        if (publicConfig.environmentName !== 'production') {
          console.log('Mixpanel EventTracking: ', event, props);
        }

        mixpanel.track(event, props);
      }
    },
    []
  );

  // begin GA Api
  const logGoogleAnalyticsEvent = React.useCallback((event: string, props: IEventProps | ILogEventParams) => {
    const token = publicConfig.analytics.googleAnalytics.token;
    if (token) {
      if (!isServer) {
        if (!props) {
          props = {};
        }

        props['source'] = 'next-app';

        const eventInfo = {};

        const eventKeys = ['category', 'label'];

        for (const key in props) {
          if (eventKeys.includes(key)) {
            eventInfo[`event_${key}`] = props[key];
          } else {
            eventInfo[key] = props[key];
          }
        }

        if (window['gtag']) {
          window['gtag']('event', event, eventInfo);
          if (publicConfig.environmentName !== 'production') {
            console.log('GA EventTracking: ', event, props);
          }
        }
      }
    }
  }, []);

  // end GA API

  // begin hubspot api
  const logHubspotEvent = React.useCallback((event: string) => {
    const hsq = window['_hsq'];

    if (!hsq) {
      return;
    }

    if (publicConfig.environmentName !== 'production') {
      // console.log('Hubspot EventTracking: ', event);
    }

    hsq.push([
      'trackEvent',
      {
        id: event,
      },
    ]);
  }, []);

  // end hubspot API

  const logAzureInsightsEvent = React.useCallback((event: string, props: IEventProps | ILogEventParams) => {
    const appInsights = window['appInsights'];

    if (appInsights) {
      if (publicConfig.environmentName !== 'production') {
        console.log('AppInsights Event Logged ', event, props);
      }

      appInsights.trackEvent({ name: event, properties: props });
    }
  }, []);

  const logEvent = React.useCallback(
    (event: string, props: IEventProps | ILogEventParams) => {
      try {
        logMixpanelEvent(event, props);

        if (isServer) {
          return false;
        }

        logGoogleAnalyticsEvent(event, props);
        logHubspotEvent(event);
        logAzureInsightsEvent(event, props);

        if (publicConfig.environmentName !== 'production') {
          console.log('Event Logged ', event, props);
        }
      } catch (e) {
        console.log('Error with event logging', e);
      }
    },
    [logAzureInsightsEvent, logGoogleAnalyticsEvent, logHubspotEvent, logMixpanelEvent]
  );

  const logFormattedEvent = React.useCallback(
    (props: IEventProps) => {
      if (!props.product || !props.item || !props.action || !props.section || !props.itemName) {
        if (publicConfig.environmentName !== 'production') {
          console.log('LogFormattedEvent props are not valid!');
        }
        return;
      }

      const event = `${props.product} ${props.item} ${props.action} ${props.section} ${props.itemName}`;
      logEvent(event, props);
    },
    [logEvent]
  );

  const logDoubleClickEvent = React.useCallback((event: string, props: IEventProps | ILogEventParams) => {
    if (!isServer) {
      if (!props) {
        props = {};
      }

      props['source'] = 'next-app';

      const eventInfo = {};

      const eventKeys = ['category', 'label'];

      for (const key in props) {
        if (eventKeys.includes(key)) {
          eventInfo[`event_${key}`] = props[key];
        } else {
          eventInfo[key] = props[key];
        }
      }

      if (window['gtag']) {
        window['gtag']('event', event, eventInfo);

        if (publicConfig.environmentName !== 'production') {
          console.log('DoubleClick EventTracking: ', event, props);
        }
      }
    }
  }, []);

  const logImpactRadiusEvent = React.useCallback(
    (options = { orderId: '0', eventId: '', customerEmail: '', customerId: '0' }) => {
      if (!window['ire']) {
        return;
      }

      const eventAction = options.eventId;

      if (!options.eventId) {
        return;
      }

      if (options.customerEmail) {
        options.customerEmail = CryptoJS.HmacSHA1(options.customerEmail, '');
      }

      window['ire']('trackConversion', eventAction, options);
    },
    []
  );

  // begin segment api
  const logSegmentPageView = React.useCallback(() => {
    LogSegmentPageView();
  }, []);

  const logSegmentTrackEvent = React.useCallback((event: string, props: { app: AppName }) => {
    LogSegmentTrackEvent(event, props);
  }, []);
  // end segment api

  // global actions
  const logPageView = React.useCallback(() => {
    try {
      if (publicConfig.environmentName !== 'production') {
        console.log('Tracking PageView');
      }
      logSegmentPageView();
    } catch (e) {
      console.log('Error with event logging', e);
    }
  }, [logSegmentPageView]);

  return {
    logEvent,
    logFormattedEvent,
    logPageView,
    logMixpanelEvent,
    logGoogleAnalyticsEvent,
    logDoubleClickEvent,
    logImpactRadiusEvent,
    logSegmentTrackEvent,
  };
};

export default useEventTracking;
