import { createGlobalStyle } from 'styled-components';

// @TEMPORARY Overrides CssBaseline until we build light and dark modes
const GlobalStyle = createGlobalStyle`
  body {
    background-color: #232A35 !important;
    color: #fff !important;
  }
`;

export default GlobalStyle;
