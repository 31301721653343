import { publicConfig } from '@rs-app/lib/config';
import { GetWalletForProfile, GetWalletForProfile_blockchainQuery_wallet } from '@rs-app/schema/schemaTypes';
import { request } from 'graphql-request';
import GetWalletGQL from './GetWallet.graphql';
import ConnectWalletGQL from './ConnectWallet.graphql';

export const getWallet = async (
  connectedWallet: string
): Promise<GetWalletForProfile_blockchainQuery_wallet | null | undefined> => {
  const variables = {
    input: {
      walletAddress: connectedWallet,
    },
  };
  const response = await request<GetWalletForProfile | null | undefined>(
    publicConfig.rsConfig.endpoints.graphqlUrl,
    GetWalletGQL,
    variables
  );
  return response?.blockchainQuery?.wallet;
};

export const connectWallet = async (
  connectedWallet: string
): Promise<GetWalletForProfile_blockchainQuery_wallet | null | undefined> => {
  const variables = {
    input: {
      walletAddress: connectedWallet,
    },
  };
  await request(publicConfig.rsConfig.endpoints.graphqlUrl, ConnectWalletGQL, variables);
  const response = await request<GetWalletForProfile>(
    publicConfig.rsConfig.endpoints.graphqlUrl,
    GetWalletGQL,
    variables
  );
  return response?.blockchainQuery?.wallet;
};
