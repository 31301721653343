import { publicConfig } from '@rs-app/lib/config';
import { OnChainAnonymousIconOption } from '@rs-app/schema/schemaTypes';

export function GetAnonIconImage(anonIcon: OnChainAnonymousIconOption | null | undefined): string {
  const replaceable = '{replace}';
  const imagePath = `${publicConfig.imagesFolder}/anonymous/Anonymous {replace}.png`;
  switch (anonIcon) {
    case OnChainAnonymousIconOption.BEAVER:
      return imagePath.replace(replaceable, 'Beaver');
    case OnChainAnonymousIconOption.CAPYBARA:
      return imagePath.replace(replaceable, 'Capybara');
    case OnChainAnonymousIconOption.ELK:
      return imagePath.replace(replaceable, 'Elk');
    case OnChainAnonymousIconOption.GORILLA:
      return imagePath.replace(replaceable, 'Gorilla');
    case OnChainAnonymousIconOption.HEDGEHOG:
      return imagePath.replace(replaceable, 'Hedgehog');
    case OnChainAnonymousIconOption.LLAMA:
      return imagePath.replace(replaceable, 'Llama');
    case OnChainAnonymousIconOption.MEERKAT:
      return imagePath.replace(replaceable, 'Meerkat');
    case OnChainAnonymousIconOption.OCTOPUS:
      return imagePath.replace(replaceable, 'Octopus');
    case OnChainAnonymousIconOption.OWL:
      return imagePath.replace(replaceable, 'Owl');
    case OnChainAnonymousIconOption.PENGUIN:
      return imagePath.replace(replaceable, 'Penguin');
    case OnChainAnonymousIconOption.RABBIT:
      return imagePath.replace(replaceable, 'Rabbit');
    case OnChainAnonymousIconOption.KIWI:
    case OnChainAnonymousIconOption.UNSPECIFIED:
    default:
      return imagePath.replace(replaceable, 'Kiwi');
  }
}
