import { Document } from '@rs-app/models/Documents';
import * as download from 'downloadjs';
/* cspell:disable-next-line*/
import JSZip from 'jszip';

export function DownloadFile(downloadUrl: string, fileName: string, contentType = 'application/pdf'): void {
  getBlobFromUrl(downloadUrl)
    .then(blob => download(blob, fileName, contentType))
    .catch(error => console.log(error));
}

export async function DownloadFilesFromUrlsAndZip(documentUrls: string[], filename: string): Promise<boolean> {
  const zip = new JSZip();
  documentUrls.forEach((documentUrl: string) => {
    const filenameWithExt = documentUrl.substring(documentUrl.lastIndexOf('/') + 1);
    zip.file(filenameWithExt ?? '', getBlobFromUrl(documentUrl ?? ''), {
      binary: true,
    });
  });
  return await zip.generateAsync({ type: 'blob' }).then(content => {
    download(content, filename, 'application/zip');
    return true;
  });
}

export async function DownloadFilesAndZip(documents: Document[], fileName: string): Promise<boolean> {
  const zip = new JSZip();
  documents.forEach((document: Document) => {
    zip.file(document.FileNameWithExt ?? '', getBlobFromUrl(document.DocumentUrl ?? ''), {
      binary: true,
    });
  });
  return await zip.generateAsync({ type: 'blob' }).then(content => {
    download(content, fileName, 'application/zip');
    return true;
  });
}

function getBlobFromUrl(downloadUrl: string): Promise<Blob> {
  return fetch(downloadUrl).then(response => response.blob());
}
