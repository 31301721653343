/* cspell:disable */

import { publicConfig } from '@rs-app/lib/config';
import { abortableFetch } from 'abortcontroller-polyfill/dist/cjs-ponyfill';
import { HttpsAgent } from 'agentkeepalive';
import { ApolloClient, HttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import fetch from 'isomorphic-unfetch';
import { isServer } from '@rs-app/utils';

/* cspell:enable */

let apolloClient: ApolloClient<NormalizedCacheObject>;

// Polyfill fetch() on the server (used by apollo-client)
if (isServer) {
  global.fetch = fetch;
}

function create(initialState: NormalizedCacheObject = {}): ApolloClient<NormalizedCacheObject> {
  const httpLink = new HttpLink({
    uri: publicConfig.rsConfig.endpoints.graphqlUrl, // Server URL (must be absolute)
    credentials: 'same-origin', // Additional fetch() options like `credentials` or `headers`
    fetchOptions: {
      agent: new HttpsAgent(),
    },
    fetch: (uri, options) => {
      // For auth0 library conflicts - https://github.com/auth0/auth0-react/issues/323#issuecomment-1027985338
      // cspell:disable-next-line
      return abortableFetch(fetch).fetch(uri, options);
    },
  });

  const errorLink = onError(({ operation, graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message }) => {
        console.log(
          `[GraphQL error]:\r\nOperation Name: ${operation.operationName}\r\nVariables: ${JSON.stringify(
            operation.variables
          )}\r\nMessage: ${message}`
        );
      });
    }

    if (networkError) console.log(`[Network error]: ${networkError}`);
  });

  // Check out https://github.com/zeit/next.js/pull/4611 if you want to use the AWSAppSyncClient
  return new ApolloClient({
    connectToDevTools: !isServer,
    ssrMode: true, //!(process as any).browser, // Disables forceFetch on the server (so queries are only run once)
    link: errorLink.concat(httpLink), // authLink.concat(httpLink), //
    // link : ApolloLink.from([errorLink, middlewareLink, authLink, httpLink]),
    cache: new InMemoryCache().restore(initialState),
    defaultOptions: {
      watchQuery: { fetchPolicy: 'no-cache', errorPolicy: 'all' },
    },
    name: `${publicConfig.app.name}`,
    version: `${publicConfig.app.version}`,
  });
}

// ERROR Policies
// ==============
// none: This is the default policy to match how Apollo Client 1.0 worked. Any GraphQL Errors are treated the same as network errors and any data is ignored from the response.
// ignore: Ignore allows you to read any data that is returned alongside GraphQL Errors, but doesn't save the errors or report them to your UI.
// all: Using the all policy is the best way to notify your users of potential issues while still showing as much data as possible from your server. It saves both data and errors into the Apollo Cache so your UI can use them.
export default function initApollo(initialState: NormalizedCacheObject = {}): ApolloClient<NormalizedCacheObject> {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (isServer) {
    return create(initialState);
  }

  // Reuse client on the client-side
  if (!apolloClient) {
    apolloClient = create(initialState);
  }

  return apolloClient;
}
