// cspell:disable

import '@rs-app/lib/FontAwesomeIcons';
import '@rs-app/styles/WordpressBlogStyles/index.scss';

import * as React from 'react';

import { ApolloClient, ApolloProvider, NormalizedCacheObject } from '@apollo/client';
import NextApp, { AppProps as NextAppProps } from 'next/app';

import { AppContext } from 'next/app';
import { CssBaseline } from '@mui/material';
import { CustomNextPage } from '@rs-app/@types';
import GlobalStyle from '@rs-app/styles/globalStyles';
import { NavigationProvider } from '@rs-app/lib/providers/Navigation';
import { NextPageContext } from 'next';
import { RoofstockOnChainWeb3Provider } from '@rs-app/lib/web3';
import Router from 'next/router';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { ThemeProvider } from '@mui/material';
import { init as initApm } from '@elastic/apm-rum';
import initApollo from '@rs-apollo-lib/initApollo';
import { onChainTheme } from '@rs-app/styles/onChainTheme';
import { parseCookies } from '@rs-app/utils';
import { publicConfig } from '@rs-app/lib/config';
import useEventTracking from '@rs-app/lib/hooks/useEventTracking';
import { withLDProvider } from 'launchdarkly-react-client-sdk';

type CustomAppProps = {
  apolloInitialState: NormalizedCacheObject;
};

type AppProps = Omit<NextAppProps, 'Component'> &
  CustomAppProps & {
    Component: CustomNextPage;
  };

const App = ({ Component, pageProps, apolloInitialState }: AppProps) => {
  const { logPageView } = useEventTracking();

  React.useEffect(() => {
    const onRouteChangeComplete = url => {
      logPageView(url);
    };
    Router.events.on('routeChangeComplete', onRouteChangeComplete);
    return () => Router.events.off('routeChangeComplete', onRouteChangeComplete);
  }, [logPageView]);

  useElasticRUM();

  const apolloClient = initApollo(apolloInitialState);
  const getLayout = Component.getLayout ?? (page => page);
  return (
    <StyledThemeProvider theme={onChainTheme}>
      <ThemeProvider theme={onChainTheme}>
        <CssBaseline />
        <GlobalStyle />
        <NavigationProvider>
          <ApolloProvider client={apolloClient}>
            <RoofstockOnChainWeb3Provider>
              {/* @ts-expect-error JSX element type 'Component' does not have any construct or call signatures. */}
              {getLayout(<Component {...pageProps} />)}
            </RoofstockOnChainWeb3Provider>
          </ApolloProvider>
        </NavigationProvider>
      </ThemeProvider>
    </StyledThemeProvider>
  );
};

const TestHeader = 'x-rs-test';

interface CustomPageContext extends NextPageContext {
  apolloClient: ApolloClient<NormalizedCacheObject>;
}

type CustomAppContext = Omit<AppContext, 'ctx'> & {
  ctx: CustomPageContext;
  Component: CustomNextPage;
};

App.getInitialProps = async (appContext: CustomAppContext): Promise<CustomAppProps> => {
  const cookies = parseCookies(appContext.ctx.req);
  maybeRedirectToAccessCode(appContext, cookies);
  const apolloClient = initApollo();
  appContext.ctx.apolloClient = apolloClient;

  const appProps = await NextApp.getInitialProps(appContext);
  return {
    ...appProps,
    apolloInitialState: apolloClient.cache.extract(),
  };
};

export default withLDProvider({
  clientSideID: publicConfig.launchDarkly.clientSideID,
  // @ts-expect-error Don't know how to fix.
})(App);

const useElasticRUM = () => {
  React.useEffect(() => {
    const elasticApmConfig = publicConfig.elastic?.apm;
    const isElasticApmEnabled = elasticApmConfig?.enabled;

    if (isElasticApmEnabled) {
      const serviceName = elasticApmConfig.clientServiceName;
      const serverUrl = elasticApmConfig.serverUrl;
      let serviceVersion = publicConfig.app.version;
      // need to get build version without the "-R1" part.
      const regex = /-(R\d+-?\d*)/;
      serviceVersion = serviceVersion.replace(regex, '');

      initApm({
        // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
        serviceName,
        // Set custom APM Server URL (default: http://localhost:8200)
        serverUrl,
        // Set service version (required for sourcemap feature)
        serviceVersion,
        environment: publicConfig.environmentName,
      });
    }
  }, []);
};

const maybeRedirectToAccessCode = (appContext: CustomAppContext, cookies: Record<string, string>) => {
  const isAccessCodeCookieFound = !!cookies[publicConfig.auth.accessCode.cookieName];
  const isAccessCodeRememberMeCookieFound = !!cookies[publicConfig.auth.accessCode.rememberMeCookieName];
  const hasTestHeader = appContext.ctx.req?.headers?.[TestHeader];

  if (
    publicConfig.auth.accessCode.enabled &&
    !(isAccessCodeCookieFound || isAccessCodeRememberMeCookieFound || hasTestHeader)
  ) {
    if (!appContext.ctx.req?.url?.includes('/internal/access-code') && !appContext.ctx.req?.url?.includes('/probe')) {
      return redirect(`${publicConfig.app.basePath}/internal/access-code`, appContext.ctx);
    }
  }
};

const redirect = (target: string, context?: NextPageContext): void => {
  if (context && context.res) {
    // server
    // 303: "See other"
    if (!context.res.headersSent) {
      context.res.writeHead(303, { Location: target });
      context.res.end();
    }
  } else {
    // In the browser, we just pretend like this never even happened ;)
    Router.push(target);
  }
};
