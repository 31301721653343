import { initializeConnector } from '@web3-react/core';
import { CoinbaseWallet } from '@web3-react/coinbase-wallet';
import { MetaMask } from '@web3-react/metamask';
// cspell:disable
import { WalletConnect } from '@web3-react/walletconnect';
// cspell:enable
import { URLS, desiredChainId } from './chains';

const [coinbaseWallet, coinbaseWalletHooks] = initializeConnector<CoinbaseWallet>(
  actions =>
    new CoinbaseWallet({
      actions,
      options: {
        url: URLS[desiredChainId][0],
        appName: 'web3-react',
      },
    })
);

export { coinbaseWallet, coinbaseWalletHooks };

const [metaMask, metaMaskHooks] = initializeConnector<MetaMask>(actions => new MetaMask({ actions }));

export { metaMask, metaMaskHooks };

const [walletConnect, walletConnectHooks] = initializeConnector<WalletConnect>(
  actions =>
    new WalletConnect({
      actions,
      options: {
        rpc: URLS,
      },
    })
);

export { walletConnect, walletConnectHooks };
