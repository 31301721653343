// See: https://github.com/FortAwesome/react-fontawesome#build-a-library-to-reference-icons-throughout-your-app-more-conveniently

import {
  faCaretDown,
  faCaretUp,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faChevronCircleLeft,
  faChevronCircleRight,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCog,
  faDollarSign,
  faDownload,
  faEnvelope,
  faExclamationCircle,
  faExclamationTriangle,
  faFileAlt,
  faHeart,
  faHome,
  faHomeLgAlt,
  faIdCard,
  faLink,
  faLock,
  faMapMarker,
  faPen,
  faPencil,
  faPhone,
  faPlayCircle,
  faQuestionCircle,
  faQuoteLeft,
  faQuoteRight,
  faSquare,
  faStar,
  faStarHalf,
  faSync,
  faThLarge,
  faTimes,
  faTrashAlt,
  faUpload,
  faUser,
  faWrench,
} from '@fortawesome/pro-solid-svg-icons';

import { library } from '@fortawesome/fontawesome-svg-core';

// import { faCheck } from '@fortawesome/pro-regular-svg-icons';

function addProSolidSvgIconsToLibrary() {
  library.add(
    faCheckCircle,
    faChevronDown,
    faChevronUp,
    faChevronRight,
    faChevronLeft,
    faCircle,
    faDollarSign,
    faDownload,
    faIdCard,
    faPlayCircle,
    faQuestionCircle,
    faQuoteLeft,
    faQuoteRight,
    faSquare,
    faStar,
    faStarHalf,
    faTimes,
    faUser,
    faPhone,
    faEnvelope,
    faLock,
    faCheck,
    faHome,
    faChevronCircleLeft,
    faChevronCircleRight,
    faTrashAlt,
    faPencil,
    faHeart,
    faThLarge,
    faMapMarker,
    faWrench,
    faCog,
    faExclamationTriangle,
    faExclamationCircle,
    faHomeLgAlt,
    faLink,
    faUpload,
    faSync,
    faCheckSquare,
    faPen,
    faFileAlt,
    faCaretDown,
    faCaretUp
  );
}

addProSolidSvgIconsToLibrary();
