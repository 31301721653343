import { IncomingMessage } from 'http';
import { NextPageContext } from 'next';
import cookie from 'cookie';
import { publicConfig } from '@rs-app/lib/config';
import { isServer } from './isServer';

export const siteAnnouncementIdDismissedCookieName = 'SiteAnnouncementDismissed';
export const downPaymentCookieName = 'rs-adp';
export const interestRateCookieName = 'rs-air';

export function setCookie(ctx: NextPageContext | null, name: string, value: string, options = {}): void {
  if (ctx && ctx.res) {
    ctx.res.setHeader('Set-Cookie', cookie.serialize(name, value, options));
  }

  if (!isServer) {
    document.cookie = cookie.serialize(name, value, options);
  }
}

export function parseCookies(req?: IncomingMessage, options = {}): { [key: string]: string } {
  return cookie.parse(req ? req.headers.cookie || '' : document.cookie, options);
}

export function destroyCookie(ctx: NextPageContext | null, name: string): void {
  if (ctx && ctx.res) {
    ctx.res.setHeader(
      'Set-Cookie',
      cookie.serialize(name, '', {
        maxAge: -1,
        path: '/',
        domain: publicConfig.auth.cookieDomain,
      })
    );
  }

  if (!isServer) {
    document.cookie = cookie.serialize(name, '', {
      maxAge: -1,
      path: '/',
      domain: publicConfig.auth.cookieDomain,
    });
  }
}
