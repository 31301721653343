import { useRouter } from 'next/router';
import * as React from 'react';

type Navigation = {
  state: 'idle' | 'loading';
};

const navigationContext = React.createContext<Navigation>({ state: 'idle' });

export const NavigationProvider = ({ children }: React.PropsWithChildren) => {
  const router = useRouter();
  const [navigation, setNavigation] = React.useState<Navigation>({ state: 'idle' });

  React.useEffect(() => {
    const handleStart = () => {
      setNavigation({ state: 'loading' });
    };
    const handleStop = () => {
      setNavigation({ state: 'idle' });
    };
    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleStop);
    router.events.on('routeChangeError', handleStop);
    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleStop);
      router.events.off('routeChangeError', handleStop);
    };
  }, [router]);

  return <navigationContext.Provider value={navigation}>{children}</navigationContext.Provider>;
};

export const useNavigation = () => {
  const navigation = React.useContext(navigationContext);
  return navigation;
};
