// See: https://github.com/FortAwesome/react-fontawesome#build-a-library-to-reference-icons-throughout-your-app-more-conveniently

import {
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faBars,
  faCalendarAlt,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faChevronCircleLeft,
  faChevronCircleRight,
  faChevronDoubleRight,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faClock,
  faCloudDownload,
  faCloudDownloadAlt,
  faCompass,
  faCreditCard,
  faDollarSign,
  faEnvelope,
  faExternalLink,
  faFileAlt,
  faFileImage,
  faFilePdf,
  faFileSpreadsheet,
  faFolderOpen,
  faHeart,
  faHistory,
  faHome,
  faInfoCircle,
  faLock,
  faMapMarked,
  faMapMarkerAlt,
  faMinusCircle,
  faPencil,
  faPercent,
  faPhone,
  faPlusCircle,
  faQuestion,
  faQuestionCircle,
  faSchool,
  faShare,
  faShieldCheck,
  faSquare,
  faSync,
  faTag,
  faThLarge,
  faTimes,
  faTrashAlt,
  faUniversity,
  faUserCircle,
} from '@fortawesome/pro-light-svg-icons';

import { library } from '@fortawesome/fontawesome-svg-core';

// import { faChevronCircleLeft } from '@fortawesome/pro-solid-svg-icons';
// import { faChevronCircleRight } from '@fortawesome/pro-regular-svg-icons';

function addProLightSvgIconsToLibrary() {
  library.add(
    faAngleDown,
    faAngleUp,
    faAngleRight,
    faAngleLeft,
    faBars,
    faCheckCircle,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faCircle,
    faClock,
    faCloudDownloadAlt,
    faCompass,
    faDollarSign,
    faEnvelope,
    faExternalLink,
    faExternalLink,
    faFilePdf,
    faFolderOpen,
    faHeart,
    faHistory,
    faHome,
    faInfoCircle,
    faLock,
    faMapMarked,
    faMapMarkerAlt,
    faPlusCircle,
    faMinusCircle,
    faPencil,
    faPercent,
    faPhone,
    faQuestion,
    faSchool,
    faShare,
    faShieldCheck,
    faThLarge,
    faTimes,
    faUniversity,
    faUserCircle,
    faChevronCircleLeft,
    faChevronCircleRight,
    faSquare,
    faCheckSquare,
    faFileAlt,
    faQuestionCircle,
    faCreditCard,
    faFileImage,
    faCalendarAlt,
    faTrashAlt,
    faFileSpreadsheet,
    faChevronDoubleRight,
    faSync,
    faTag,
    faCheck,
    faCloudDownload
  );
}

addProLightSvgIconsToLibrary();
