// See: https://github.com/FortAwesome/react-fontawesome#build-a-library-to-reference-icons-throughout-your-app-more-conveniently

import {
  faFacebook,
  faFacebookF,
  faFacebookSquare,
  faLinkedin,
  faLinkedinIn,
  faTwitter,
  faWhatsapp,
} from '@fortawesome/free-brands-svg-icons';

import { library } from '@fortawesome/fontawesome-svg-core';

function addFreeBrandsSvgIconsToLibrary() {
  library.add(faFacebook, faFacebookF, faFacebookSquare, faLinkedin, faTwitter, faLinkedinIn, faWhatsapp);
}

addFreeBrandsSvgIconsToLibrary();
