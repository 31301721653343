import type { AddEthereumChainParameter } from '@web3-react/types';
import { publicConfig } from '../config';

// cspell:disable

const ETH: AddEthereumChainParameter['nativeCurrency'] = {
  name: 'Ether',
  symbol: 'ETH',
  decimals: 18,
};

const MATIC: AddEthereumChainParameter['nativeCurrency'] = {
  name: 'Matic',
  symbol: 'MATIC',
  decimals: 18,
};

const CELO: AddEthereumChainParameter['nativeCurrency'] = {
  name: 'Celo',
  symbol: 'CELO',
  decimals: 18,
};

type BasicChainInformation = {
  urls: string[];
  name: string;
};

type ExtendedChainInformation = BasicChainInformation & {
  nativeCurrency: AddEthereumChainParameter['nativeCurrency'];
  blockExplorerUrls: AddEthereumChainParameter['blockExplorerUrls'];
};

export const CHAINS: { [chainId: number]: BasicChainInformation | ExtendedChainInformation } = {
  1: {
    urls: [`https://mainnet.infura.io/v3/${publicConfig.rsOnChain.infuraId}`, 'https://cloudflare-eth.com'],
    name: 'Mainnet',
  },
  3: {
    urls: [`https://ropsten.infura.io/v3/${publicConfig.rsOnChain.infuraId}`],
    name: 'Ropsten',
  },
  4: {
    urls: [`https://rinkeby.infura.io/v3/${publicConfig.rsOnChain.infuraId}`],
    name: 'Rinkeby',
  },
  5: {
    urls: [`https://goerli.infura.io/v3/${publicConfig.rsOnChain.infuraId}`],
    name: 'Görli',
  },
  42: {
    urls: [`https://kovan.infura.io/v3/${publicConfig.rsOnChain.infuraId}`],
    name: 'Kovan',
  },
  // Optimism
  10: {
    urls: [`https://optimism-mainnet.infura.io/v3/${publicConfig.rsOnChain.infuraId}`, 'https://mainnet.optimism.io'],
    name: 'Optimism',
    nativeCurrency: ETH,
    blockExplorerUrls: ['https://optimistic.etherscan.io'],
  },
  69: {
    urls: [`https://optimism-kovan.infura.io/v3/${publicConfig.rsOnChain.infuraId}`, 'https://kovan.optimism.io'],
    name: 'Optimism Kovan',
    nativeCurrency: ETH,
    blockExplorerUrls: ['https://kovan-optimistic.etherscan.io'],
  },
  // Arbitrum
  42161: {
    urls: [`https://arbitrum-mainnet.infura.io/v3/${publicConfig.rsOnChain.infuraId}`, 'https://arb1.arbitrum.io/rpc'],
    name: 'Arbitrum One',
    nativeCurrency: ETH,
    blockExplorerUrls: ['https://arbiscan.io'],
  },
  421611: {
    urls: [
      `https://arbitrum-rinkeby.infura.io/v3/${publicConfig.rsOnChain.infuraId}`,
      'https://rinkeby.arbitrum.io/rpc',
    ],
    name: 'Arbitrum Testnet',
    nativeCurrency: ETH,
    blockExplorerUrls: ['https://testnet.arbiscan.io'],
  },
  // Polygon
  137: {
    urls: [`https://polygon-mainnet.infura.io/v3/${publicConfig.rsOnChain.infuraId}`, 'https://polygon-rpc.com'],
    name: 'Polygon Mainnet',
    nativeCurrency: MATIC,
    blockExplorerUrls: ['https://polygonscan.com'],
  },
  80001: {
    urls: [`https://polygon-mumbai.infura.io/v3/${publicConfig.rsOnChain.infuraId}`],
    name: 'Polygon Mumbai',
    nativeCurrency: MATIC,
    blockExplorerUrls: ['https://mumbai.polygonscan.com'],
  },
  // Celo
  42220: {
    urls: ['https://forno.celo.org'],
    name: 'Celo',
    nativeCurrency: CELO,
    blockExplorerUrls: ['https://explorer.celo.org'],
  },
  44787: {
    urls: ['https://alfajores-forno.celo-testnet.org'],
    name: 'Celo Alfajores',
    nativeCurrency: CELO,
    blockExplorerUrls: ['https://alfajores-blockscout.celo-testnet.org'],
  },
};

export const URLS: { [chainId: number]: string[] } = Object.keys(CHAINS).reduce<{ [chainId: number]: string[] }>(
  (accumulator, chainId) => {
    const validURLs: string[] = CHAINS[Number(chainId)].urls;

    if (validURLs.length) {
      accumulator[Number(chainId)] = validURLs;
    }

    return accumulator;
  },
  {}
);

const chainIdByNetworkName = {
  Mainnet: 1,
  Goerli: 5,
};
export const desiredChainId = publicConfig.rsOnChain.etherscanBaseUri.startsWith('https://goerli.etherscan.io')
  ? chainIdByNetworkName.Goerli
  : chainIdByNetworkName.Mainnet;
