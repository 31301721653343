import * as React from 'react';
import * as Dialog from '@radix-ui/react-dialog';
// cspell:disable
import { publicConfig } from '@rs-app/lib/config';
import { useWeb3React, Web3ReactHooks, Web3ReactProvider } from '@web3-react/core';
import { MetaMask } from '@web3-react/metamask';
import { CoinbaseWallet } from '@web3-react/coinbase-wallet';
import { WalletConnect } from '@web3-react/walletconnect';
import {
  coinbaseWallet,
  coinbaseWalletHooks,
  metaMask,
  metaMaskHooks,
  walletConnect,
  walletConnectHooks,
} from './connectors';
import { Box, ButtonBase, IconButton, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import { Connector } from '@web3-react/types';
import styled from 'styled-components';
import { desiredChainId } from './chains';
import { RoofstockOnChainMembershipABI__factory } from './generated';

const connectors: [MetaMask | CoinbaseWallet | WalletConnect, Web3ReactHooks][] = [
  [metaMask, metaMaskHooks],
  [coinbaseWallet, coinbaseWalletHooks],
  [walletConnect, walletConnectHooks],
];

const connectorByName = {
  metaMask,
  coinbaseWallet,
  walletConnect,
};

const STORAGE_CONNECTOR_KEY = 'connector';

export const RoofstockOnChainWeb3Provider = ({ children }) => {
  const [connector, setConnector] = React.useState<Connector | undefined>(
    typeof localStorage !== 'undefined' ? connectorByName[localStorage.getItem(STORAGE_CONNECTOR_KEY) ?? ''] : undefined
  );
  const updateConnector = React.useCallback((connector?: Connector) => {
    if (connector) {
      setConnector(connector);
      if (connector instanceof MetaMask) {
        localStorage.setItem(STORAGE_CONNECTOR_KEY, 'metaMask');
      } else if (connector instanceof CoinbaseWallet) {
        localStorage.setItem(STORAGE_CONNECTOR_KEY, 'coinbaseWallet');
      } else if (connector instanceof WalletConnect) {
        localStorage.setItem(STORAGE_CONNECTOR_KEY, 'walletConnect');
      }
    } else {
      setConnector(undefined);
      localStorage.removeItem(STORAGE_CONNECTOR_KEY);
    }
  }, []);
  return (
    <Web3ReactProvider connectors={connectors} connectorOverride={connector}>
      <InnerRoofstockOnChainWeb3Provider connector={connector} setConnector={updateConnector}>
        {children}
      </InnerRoofstockOnChainWeb3Provider>
    </Web3ReactProvider>
  );
};

type RoofstockOnChainWeb3 = {
  currentAccount: string | undefined;
  roofstockOnChainMembershipToken: string | undefined;
  roofstockOnChainMembershipVerified: boolean;
  reloadRoofstockOnChainMembership: () => Promise<void>;
  disconnectWeb3: () => void;
  mint: () => Promise<unknown>;
  openWeb3Modal: () => void;
  closeWeb3Modal: () => void;
  mintingStatus: 'idle' | 'loading';
};

const roofstockOnChainWeb3Context = React.createContext<RoofstockOnChainWeb3>({
  currentAccount: undefined,
  roofstockOnChainMembershipToken: '',
  roofstockOnChainMembershipVerified: false,
  reloadRoofstockOnChainMembership: () => Promise.resolve(),
  disconnectWeb3: () => void 0,
  mint: () => Promise.resolve(),
  openWeb3Modal: () => void 0,
  closeWeb3Modal: () => void 0,
  mintingStatus: 'idle',
});

export const useRoofstockOnChainWeb3 = () => React.useContext(roofstockOnChainWeb3Context);

const InnerRoofstockOnChainWeb3Provider = ({
  children,
  connector,
  setConnector,
}: React.PropsWithChildren<{
  connector: Connector | undefined;
  setConnector: (connector?: Connector) => void;
}>) => {
  const web3React = useWeb3React();
  const [web3ModalOpen, setWeb3ModalOpen] = React.useState(false);
  const openWeb3Modal = React.useCallback(() => setWeb3ModalOpen(true), []);
  const closeWeb3Modal = React.useCallback(() => setWeb3ModalOpen(false), []);
  const [roofstockOnChainMembershipToken, setRoofstockOnChainMembershipToken] = React.useState<string | undefined>(
    undefined
  );
  const [roofstockOnChainMembershipVerified, setRoofstockOnChainMembershipVerified] = React.useState(false);
  const [mintingStatus, setMintingStatus] = React.useState<RoofstockOnChainWeb3['mintingStatus']>('idle');

  const disconnectWeb3 = React.useCallback(() => {
    setConnector();
    setRoofstockOnChainMembershipToken(undefined);
    setRoofstockOnChainMembershipVerified(false);
    // Don't use web3React.connector since that defaults to the priority connector when not overriding their connector.
    connector?.resetState();
  }, [connector, setConnector]);

  const mint = React.useCallback(async () => {
    if (roofstockOnChainMembershipToken || !web3React.account || !web3React.provider || mintingStatus === 'loading') {
      return;
    }

    const roofstockOnChainMembershipContract = RoofstockOnChainMembershipABI__factory.connect(
      publicConfig.rsOnChain.membershipContractAddress,
      web3React.provider.getSigner(web3React.account)
    );
    setMintingStatus('loading');

    try {
      const transactionResponse = await roofstockOnChainMembershipContract.mint();
      // Wait for transaction to be confirmed before fetching for tokens.
      await transactionResponse.wait();
      const roofstockOnChainMembershipTokens = await roofstockOnChainMembershipContract.functions.tokensOfOwner(
        web3React.account
      );
      const newRoofstockOnChainMembershipToken = roofstockOnChainMembershipTokens[0].toString();
      setRoofstockOnChainMembershipToken(newRoofstockOnChainMembershipToken);
      return roofstockOnChainMembershipTokens;
    } finally {
      setMintingStatus('idle');
    }
  }, [mintingStatus, roofstockOnChainMembershipToken, web3React.account, web3React.provider]);

  const reloadRoofstockOnChainMembership = React.useCallback(async () => {
    if (!web3React.account || !web3React.provider || web3React.chainId !== desiredChainId) {
      setRoofstockOnChainMembershipToken(undefined);
      setRoofstockOnChainMembershipVerified(false);
      return;
    }

    try {
      const roofstockOnChainMembershipContract = RoofstockOnChainMembershipABI__factory.connect(
        publicConfig.rsOnChain.membershipContractAddress,
        web3React.provider
      );
      const [[roofstockOnChainMembershipToken], [roofstockOnChainMembershipVerified]] = await Promise.all([
        roofstockOnChainMembershipContract.functions.tokensOfOwner(web3React.account),
        roofstockOnChainMembershipContract.functions.isAllowed(web3React.account),
      ]);
      setRoofstockOnChainMembershipToken(roofstockOnChainMembershipToken.toString());
      setRoofstockOnChainMembershipVerified(roofstockOnChainMembershipVerified);
    } catch (error) {
      disconnectWeb3();
      throw error;
    }
  }, [disconnectWeb3, web3React.account, web3React.chainId, web3React.provider]);

  React.useEffect(() => {
    if (web3React.chainId && desiredChainId !== web3React.chainId) {
      // When the user switches to a network not compatible with this env, disconnect their wallet because we don't
      // want to show the user wrong information.
      disconnectWeb3();
    }
  }, [disconnectWeb3, web3React.chainId]);

  React.useEffect(() => {
    /**
     * Connects to the new provider when the `connector` changes.
     */
    const connectToProvider = async () => {
      if (!connector) {
        disconnectWeb3();
        return;
      }

      try {
        // We need to run `connectEagerly` first because `activate` can't detect MetaMask on load.
        await connector.connectEagerly?.();
        await connector.activate(desiredChainId);
      } catch (reason) {
        // Ensure that they can't successfully connect unless they switch networks, and clear out the cached provider.
        disconnectWeb3();

        if (isMetaMaskError(reason) && reason.code === 4001) {
          // The user rejected the request to switch network in MetaMask.
          return;
        }

        if (reason instanceof Error && reason.message === 'User rejected request') {
          // The user rejected the request to switch network in Coinbase Wallet.
          return;
        }

        throw reason;
      }
    };
    connectToProvider();
  }, [connector, disconnectWeb3]);

  React.useEffect(() => {
    reloadRoofstockOnChainMembership();
  }, [reloadRoofstockOnChainMembership]);

  return (
    <roofstockOnChainWeb3Context.Provider
      value={{
        currentAccount: web3React.account,
        roofstockOnChainMembershipToken,
        roofstockOnChainMembershipVerified,
        reloadRoofstockOnChainMembership,
        disconnectWeb3,
        mint,
        openWeb3Modal,
        closeWeb3Modal,
        mintingStatus,
      }}
    >
      {children}
      <Dialog.Root open={web3ModalOpen} onOpenChange={setWeb3ModalOpen}>
        <Dialog.Portal>
          <Dialog.Overlay asChild>
            <Overlay
              position="fixed"
              top="0"
              bottom="0"
              left="0"
              right="0"
              overflow="auto"
              display="flex"
              justifyContent="center"
              bgcolor="rgba(35, 42, 53, 0.8)"
              zIndex={theme => theme.zIndex.modal}
            >
              <Dialog.Content asChild>
                <Box
                  border="1px solid white"
                  borderRadius="16px"
                  bgcolor={theme => theme.palette.slate.p900}
                  height="fit-content"
                  marginTop="10rem"
                  marginBottom="4rem"
                  maxWidth="685px"
                  padding="2.5rem"
                  position="relative"
                >
                  <Dialog.Title asChild>
                    <Typography
                      color="transparent"
                      component="h2"
                      textAlign="center"
                      variant="h6"
                      sx={{
                        background: 'linear-gradient(90.06deg, #E7C0E5 14.05%, #B5DCED 93.23%)',
                        backgroundClip: 'text',
                      }}
                    >
                      Connect your wallet to mint your membership token.
                    </Typography>
                  </Dialog.Title>
                  <Box
                    marginTop="2rem"
                    display="grid"
                    gridTemplateColumns={{ xs: '1fr', sm: '1fr 1fr' }}
                    gap="2rem"
                    justifyContent="center"
                  >
                    {typeof window !== 'undefined' && window.ethereum?.isMetaMask && (
                      <WalletLayout
                        imageSrc="/images/wallet/metamask.png"
                        walletName="MetaMask"
                        description="Connect to your MetaMask Wallet"
                        onClick={async () => {
                          try {
                            await metaMask.activate(desiredChainId);
                          } catch (caught) {
                            if (isMetaMaskError(caught) && caught.code === 4001) {
                              // The user rejected the request to switch network in MetaMask.
                              return;
                            }

                            throw caught;
                          }

                          setConnector(metaMask);
                        }}
                      />
                    )}
                    <WalletLayout
                      imageSrc="/images/wallet/walletconnect.png"
                      walletName="WalletConnect"
                      description="Scan with WalletConnect to connect"
                      onClick={async () => {
                        try {
                          await walletConnect.activate(desiredChainId);
                        } catch (caught) {
                          if (caught instanceof Error && caught.message === 'User closed modal') {
                            // User closed modal from WalletConnect.
                            return;
                          }

                          throw caught;
                        }

                        setConnector(walletConnect);
                      }}
                    />
                    <WalletLayout
                      imageSrc="/images/wallet/coinbase.png"
                      walletName="Coinbase"
                      description="Connect to your Coinbase Wallet"
                      onClick={async () => {
                        try {
                          await coinbaseWallet.activate(desiredChainId);
                        } catch (caught) {
                          if (caught instanceof Error) {
                            if (caught.message === 'User rejected request') {
                              // The user rejected the request to switch network in Coinbase Wallet.
                              return;
                            }

                            if (caught.message === 'User denied account authorization') {
                              // User denied connecting wallet from Coinbase.
                              return;
                            }
                          }

                          throw caught;
                        }

                        setConnector(coinbaseWallet);
                      }}
                    />
                  </Box>
                  <Dialog.Close asChild>
                    <IconButton
                      aria-label="Close wallet providers modal"
                      color="primary"
                      sx={{ position: 'absolute', top: '1rem', right: '1rem' }}
                    >
                      <Close />
                    </IconButton>
                  </Dialog.Close>
                </Box>
              </Dialog.Content>
            </Overlay>
          </Dialog.Overlay>
        </Dialog.Portal>
      </Dialog.Root>
    </roofstockOnChainWeb3Context.Provider>
  );
};

type WalletLayoutProps = {
  imageSrc: string;
  walletName: string;
  description: string;
  onClick: () => void;
};

const WalletLayout = ({ imageSrc, walletName, description, onClick }: WalletLayoutProps) => {
  return (
    <Dialog.Close asChild>
      <ButtonBase
        onClick={onClick}
        sx={{
          bgcolor: theme => theme.palette.slate.p800,
          borderRadius: '8px',
          display: 'flex',
          flexDirection: 'column',
          padding: '1rem',
          '&:hover': {
            bgcolor: theme => theme.palette.slate.p700,
          },
          '&:focus-visible': {
            boxShadow: theme =>
              `${theme.palette.slate.p300} 0 0 0 2px, ${theme.palette.slate.p300} 0 0 0 6px, ${theme.palette.slate.p300} 0 1px 2px 0`,
          },
        }}
      >
        <img src={imageSrc} alt="" width="40px" height="40px" />
        <Typography marginTop="0.5rem" variant="body1Bold">
          {walletName}
        </Typography>
        <Typography color="#BEBCC5" marginTop="0.5rem" variant="body2Bold">
          {description}
        </Typography>
      </ButtonBase>
    </Dialog.Close>
  );
};

const Overlay = styled(Box)`
  @keyframes modal-overlay-in {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes modal-overlay-out {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  &[data-state='open'] {
    animation: modal-overlay-in 0.3s cubic-bezier(0, 0, 0.2, 1);
  }

  &[data-state='closed'] {
    animation: modal-overlay-out 0.2s cubic-bezier(0.4, 0, 1, 1);
  }
`;

export const shorten = (str: string) => `${str.slice(0, 5)}...${str.slice(-4)}`;

type MetaMaskError = {
  code: number;
  message: string;
  stack: string;
};

const isObject = (arg: unknown): arg is Record<string, unknown> => {
  return typeof arg === 'object' && arg !== null;
};

const isMetaMaskError = (arg: unknown): arg is MetaMaskError => {
  return isObject(arg) && typeof arg.code === 'number' && typeof arg.message === 'string';
};
